// @ts-nocheck
import React, { useContext } from 'react'
import { inject, observer } from 'mobx-react'
import Grid from '@material-ui/core/Grid'
import IconButtonBase from '@material-ui/core/IconButton'
import { formatPrice, getGT, isMobile, codeToLocale, getLocaleStr, getTranslatedTextByKey, getSectionTitleForItem } from 'utils/utils'
import { StoreContext } from 'contexts/StoreContext'
import Big from 'big.js'
import EditIconBase from '../common/EditIcon'
import CartSummaryVariationsChoices from './CartSummaryVariationsChoices'
import styled from 'styled-components'
import TypographyPro from 'themes/TypographyPro'
import IconComponent from 'themes/IconComponent'
import { sendEnhancedEcommerceEvent, sendCustomEvent } from '../../utils/analytics/analytics'
import { CONSTANTS } from '../../utils/constants'
import storage from '../../utils/storage'
import { useRouter } from 'next/router'
import Cart from 'mobx/Cart/Cart'

const EEE = CONSTANTS.ANALYTICS.ENHANCED_ECOMMERCE_EVENTS
const TrashCan = (props) => <IconComponent asset="/icons/trashCan.svg" {...props} />

const CloseIconBase = (props) => <IconComponent asset="/icons/close.svg" {...props} />

const IconButton = styled(IconButtonBase)`
	padding: 0;
`

const EditIcon = styled(EditIconBase)`
	width: 20px;
	height: 20px;

	& path {
		fill: var(--disable);
	}
`

const CloseIcon = styled(CloseIconBase)`
	width: 16px;
	height: 16px;

	& rect {
		stroke: var(--disable);
	}
`

const Container = styled.div`
	display: flex;
	justify-content: space-between;
`

const ItemQuantityTag = styled.span`
	mix-blend-mode: multiply;
	border-radius: 4px;
	height: 28px;
	width: 28px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 600;
	margin-inline-end: 10px;
	background: transparent;
	border: solid 1px var(--primary);
`

const ItemTitle = styled(TypographyPro)`
	color: var(--strokeMenuTitle);
	display: flex;
	align-items: center;
`

const VariationsContainer = styled.div`
	margin: 10px 0;
`

const QuantityPriceContainer = styled.div`
	display: flex;
	align-items: center;
`

const ItemTitleAndPrice = styled.div`
	display: flex;
	flex: 1;
	justify-content: space-between;
`

const PriceText = styled(TypographyPro)`
	color: var(--inactive);
	${({ theme }) => theme.typography.BodyRegular};
`

const ActionButtonsContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: auto;
	margin-inline-start: 20px;
	padding: 3px 0 0;

	@media (max-width: 768px) {
		margin-inline-start: 15px;
	}
`

const HeaderContainer = styled.div`
	display: flex;
`

const SummaryItemContainer = styled.div`
	flex: 1;
`

const CommentsContainer = styled.div`
	margin-bottom: 10px;
`

const CommentsText = styled(TypographyPro)`
	color: var(--inactive);
	margin-bottom: 10px;
`

const ItemTotalPrice = styled.div`
	color: var(--font);
	${({ theme }) => theme.typography.BodyRegularHighlighted};
	display: flex;
	border-top: 1px solid var(--optionalBackground);
	padding-top: 10px;
	margin-top: 15px;
`

const IconButtonStyle = styled(IconButton)`
	svg {
		width: 20px;
		height: 20px;
	}
`

const CartSummaryRow = inject(
	'Cart',
	'Infra',
	'User',
	'ItemAdditions',
	'Application'
)(
	observer((props) => {
		const { store, nextJS } = useContext(StoreContext)
		const rest = store.data
		const { Application, Cart, Infra, User, ItemAdditions, isCheckout } = props
		const locale = User.preferredLanguage ? codeToLocale[User.preferredLanguage] : rest?.locale
		const router = useRouter()

		const _fullStoreItem = rest?.items[props.itemId]
		let _itemId
		let _itemTitle
		let itemQuantityPrice

		if (_fullStoreItem) {
			_itemId = props.cartItem.itemId

			_itemTitle = getLocaleStr(_fullStoreItem.title, locale)

			// _itemTitle = _fullStoreItem.title[locale]
			itemQuantityPrice = new Big(props.cartItem.price).times(props.cartItem.quantity)
		}

		const deleteItem = () => {
			Infra.setNotification({
				// open: true,
				title: getTranslatedTextByKey('webviewFlow.confirmRemovalOf'),
				message: _itemTitle,
				cancelAction: () => {
					Infra.closeNotification()
				},
				okAction: async () => {
					Cart.removeItem(props.itemId, props.cartItemIdx, storage)
					if (Cart.calcNumberOfItems === 0 && (isMobile() || isCheckout)) {
						router.back()
					}

					const title = rest.items[props.itemId].title[locale]
					Infra.showSnackbar({
						snackId: 'cart',
						message: `'${title}' ${getTranslatedTextByKey('webviewFlow.removedFromYourCart')}`,
						status: 'success',
						isAttachedToElement: !isCheckout,
					})

					getGT(Cart.items, rest.items, false, (gt, charges, addedItemsFromDiscounts, deliveryInfo, response, removeDiscountByCalcGt) => {
						// console.log(`callback method since getting the grand total is async... gt: ${gt}`)
						removeDiscountByCalcGt(Cart, response.appliedDiscounts)
						Cart.setServerGrandTotal(gt)
						Cart.setServerCharges(charges)
						Cart.setServerAddedItemsFromDiscounts(addedItemsFromDiscounts)
						Cart.setServerDeliveryInfo(deliveryInfo)
						Cart.setServerSpecialDiscount(response)
					})

					sendEnhancedEcommerceEvent(
						EEE.sendRemoveFromCartEvent,
						{
							id: _itemId,
							itemTitle: _itemTitle,
							description: rest.items[props.itemId].description,
							price: props.cartItem.price,
							sectionTitle: getSectionTitleForItem(props.itemId, rest.sections, rest.locale),
							quantity: props.cartItem.quantity,
							total: Number(itemQuantityPrice.toString()), // can't use .toNumber() since it throws an error
						},
						{
							currency: rest.currency,
							countryCode: rest.countryCode,
						},
						rest,
						Cart
					)
				},
			})
		}

		const editItem = () => {
			sendCustomEvent({
				category: 'cart',
				action: 'edit',
				label: `${_itemTitle} - ${_itemId}`,
			})

			const newUrl = `${window.location.pathname}${window.location.search}${CONSTANTS.URL_QUERY_HASH_KEY.MENU_ITEM}_${props.itemId}`
			Application.dependencies.pushHistoryState(newUrl, _itemTitle)

			Cart.openMenuItem(_fullStoreItem, ItemAdditions.postInit, props.cartItemIdx)
		}

		/*		useEffect(() => {
			console.log(`CartSummaryRow - Cart.serverGrandTotal: ${Cart.serverGrandTotal}`)
		}, [Cart.serverGrandTotal]) */

		// only items with variations have something to edit, so not all items are editable
		/* const editable =
			props.cartItem.total > 0
				? !!(rest.items[props.itemId].variations && rest.items[props.itemId].variations.length > 0)
				: false */
		const editable = props.cartItem.total > 0

		// const itemTotal = new Big(props.cartItem.price).times(props.cartItem.quantity)

		// console.log(`rendering CartSummaryRow: ${_itemTitle}`)
		return (
			_fullStoreItem && (
				<Container>
					<SummaryItemContainer>
						<HeaderContainer>
							<ItemQuantityTag>
								<TypographyPro variant="BodyRegular" data-testid="cart-item-quantity">
									x{props.cartItem.quantity}
								</TypographyPro>
							</ItemQuantityTag>
							<ItemTitleAndPrice>
								<ItemTitle variant="h5" data-testid="cart-item-title">
									{_itemTitle}
								</ItemTitle>
								<QuantityPriceContainer>
									{/* Todo qunatity implementation will be here */}
									{/* <QuantityContainer> */}
									{/*	<AddIcon /> */}
									{/*	<TypographyPro variant="bodyRegular">x{props.cartItem.quantity}</TypographyPro> */}
									{/*	<RemoveIcon /> */}
									{/* </QuantityContainer> */}
									<PriceText variant="h5" data-testid="cart-item-price">
										{props.cartItem.price > 0 && formatPrice(props.cartItem.price, rest.currency, rest.countryCode, 1)}
									</PriceText>
								</QuantityPriceContainer>
							</ItemTitleAndPrice>
						</HeaderContainer>

						<VariationsContainer data-testid="variations-container">
							{props.cartItem.additionsNew?.[_itemId]?.variationsChoices && (
								/* possible recursion here for inner additions */
								<CartSummaryVariationsChoices
									itemId={props.itemId}
									cartItem={props.cartItem}
									variationsChoices={props.cartItem.additionsNew[_itemId].variationsChoices}
								/>
							)}
						</VariationsContainer>

						<ItemTotalPrice id={`${Application.page}-page-item-total-price`} data-testid="cart-summary-item-total-price">
							{formatPrice(props.cartItem.total, rest.currency, rest.countryCode)}
						</ItemTotalPrice>

						{props?.cartItem?.comment?.text && (
							<CommentsContainer>
								<CommentsText variant="BodySmallHighlighted" component="span">
									Item comments:{' '}
									<TypographyPro variant="BodySmall" component="span">
										{props.cartItem.comment.text}
									</TypographyPro>
								</CommentsText>
							</CommentsContainer>
						)}
					</SummaryItemContainer>

					{props.allowUpdate && (
						<ActionButtonsContainer>
							<IconButton
								id={`${Application.page}-page-edit-item-icon`}
								edge="start"
								color="inherit"
								size="small"
								onClick={editItem}
								style={{ visibility: editable ? 'visible' : 'hidden' }}
								data-testid="cart-edit-item-button"
							>
								<EditIcon />
							</IconButton>
							<IconButtonStyle
								id={`${Application.page}-page-remove-item-icon`}
								color="inherit"
								onClick={deleteItem}
								data-testid="cart-delete-item-button"
							>
								<TrashCan />
							</IconButtonStyle>
						</ActionButtonsContainer>
					)}
				</Container>
			)
		)
	})
)

export default CartSummaryRow
