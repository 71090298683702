/* eslint-disable react/no-multi-comp */
// @ts-nocheck
import React from 'react'
import styled from 'styled-components/macro'
import IconComponent from '../../../themes/IconComponent'
import reactNativeComms from '../../../utils/reactNativeComms'

const SocialContainer = styled.div`
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
`

const SocialMediaIcon = styled(IconComponent)`
	width: 45px;
	height: 45px;
	margin: 3px;
`

const socialIconMapping = {
	facebook: '/icons/facebookCircleDark.svg',
	instagram: '/icons/instagramCircleDark.svg',
	twitter: '/icons/xSocialCircleDark.svg',
	youtube: '/icons/youtubeCircleDark.svg',
	linkedin: '/icons/linkedinCircleDark.svg',
}

const SideMenuSocialSectionItem = (props) => {
	if (!props?.url) {
		return null
	}

	if (props.isMobileApp) {
		return (
			<a
				aria-label="Social Media Link"
				role="button"
				href={props.url}
				onClick={(e) => {
					e.preventDefault()
					reactNativeComms.sendMessage.openNativeBrowser(props.url)
				}}
				data-testid="side-menu-social-media-link"
			>
				<SocialMediaIcon asset={props.assetUrl} />
			</a>
		)
	}

	return (
		<a aria-label="Social Media Link" role="button" href={props.url} target={`_blank_${props.tag}`} data-testid="side-menu-social-media-link">
			<SocialMediaIcon asset={props.assetUrl} />
		</a>
	)
}

const SideMenuSocialSection = ({ skeletonData, isMobileApp, className }: { skeletonData?: unknown; isMobileApp?: boolean; className?: string }) => (
	<SocialContainer className={className}>
		{!!skeletonData?.socialMedia &&
			Object.entries(skeletonData?.socialMedia).map(([socialName, { link }]) => {
				const assetUrl = socialIconMapping[socialName]
				return <SideMenuSocialSectionItem key={socialName} isMobileApp={isMobileApp} tag={socialName} url={link} assetUrl={assetUrl} />
			})}
	</SocialContainer>
)

export default SideMenuSocialSection
