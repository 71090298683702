// @ts-nocheck
import React from 'react'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'
import { useClientSideRendering } from 'hooks/useClientSideRendering'

/**
 * please look at https://www.npmjs.com/package/react-content-loader under Usage, section 2
 * to see how to build a custom loader - refer to the online tool to see more options
 * @param height - optional
 * @param width - optional
 * @param viewBox - optional
 * @param children - mandatory
 * @param style - optional
 * @param className - optional
 * @returns {JSX.Element}
 * @constructor
 */
const CustomPlaceholderLoader = ({ width, height, viewBox, children, style, className }) => {
	const isClientSideRendering = useClientSideRendering()

	return (
		isClientSideRendering && (
			<ContentLoader
				width={width}
				height={height}
				viewBox={viewBox}
				backgroundColor="#f0f0f0"
				foregroundColor="#dedede"
				style={style}
				className={className}
			>
				{children}
			</ContentLoader>
		)
	)
}

CustomPlaceholderLoader.propTypes = {
	height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	viewBox: PropTypes.string,
	children: PropTypes.node.isRequired,
	style: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
	className: PropTypes.any,
}

export default CustomPlaceholderLoader
