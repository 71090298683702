// @ts-nocheck
import React, { useMemo, useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import styled, { css } from 'styled-components/macro'
import { respondAbove, respondTo } from 'styles/mixins'
import ImageComponent from 'themes/ImageComponent'
import { getTranslatedTextByKey } from 'utils/utils'
import TypographyPro from 'themes/TypographyPro'
import { CONSTANTS } from 'utils/constants'
import CloseIcon, { CloseIconItemPupopTop } from 'components/common/CloseIcon'
import { useStores } from 'hooks/useStores'

let timeoutId
const ItemImage = styled(ImageComponent)`
	display: flex;
	width: 160px;

	${respondAbove.lg`
		width: 260px;
	`}
`

const StyledEmptyOrderText = styled(TypographyPro)`
	color: var(--strokeMenuTitle);
`

const TextContainer = styled.div`
	display: flex;
	padding: ${({ $itemPopupTop }) => ($itemPopupTop ? '0 0 0 15px' : '15px')};
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
`

const ItemText = styled(TypographyPro)`
	color: var(--secondary);
`

const Container = styled.div`
	background: white;
	min-height: calc(160px / 1.91);
	${({ $itemPopupTop }) =>
		css`
			position: ${$itemPopupTop ? 'relative' : 'absolute'};
		`}
	top: 70px;
	width: 100%;
	transition: 0.5s;
	display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
	border-radius: var(--borderRadiusFrame);
	border: 1px solid var(--clear);
	bottom: 5px;
	align-items: center;
	overflow-wrap: anywhere;
	visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
	padding: ${({ $itemPopupTop }) => ($itemPopupTop ? '15px 20px' : '0')};

	${respondAbove.lg`
		${({ $newAddressBox, $itemPopupTop }) =>
			$newAddressBox &&
			!$itemPopupTop &&
			css`
				width: 600px;
				right: calc(50% - 600px / 2);
			`}
			${({ $itemPopupTop }) =>
				$itemPopupTop &&
				css`
					padding: 20px 40px 10px;
					border-radius: var(--borderRadiusFrame);
				`}
	`}

	${respondTo.md`
		${({ $newAddressBox, $itemPopupTop }) =>
			$newAddressBox &&
			$itemPopupTop &&
			css`
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			`}
	`}

	${({ $newAddressBox }) =>
		$newAddressBox &&
		css`
			z-index: 1202;
		`}
	
		${({ $isOpen }) =>
		$isOpen &&
		css`
			bottom: unset;
			top: calc(100% + 5px);
		`}
`

const ItemPopup = inject(
	'Home',
	'Application'
)(
	observer(
		({
			Application,
			Home,
			newAddressBox,
			toggleItemPopup,
			isPopupOpen,
			itemPopupTop,
		}: {
			Application?: any
			Home?: any
			newAddress?: boolean
			toggleItemPopup?: (mode: boolean) => void
			isPopupOpen?: boolean
			itemPopupTop?: boolean
		}) => {
			const { couponFlowStore } = useStores()
			const { setBackdropEnabled, clickedFeaturedItem, setClickedFeaturedItem, dealType, locale } = Home
			const itemImage = clickedFeaturedItem?.media?.img ?? clickedFeaturedItem?.media?.logo

			const itemTitle = useMemo(() => {
				try {
					let title = clickedFeaturedItem?.title[locale.msg]
					if (dealType === CONSTANTS.DEALS_TYPES.COUPON && !title) {
						title = getTranslatedTextByKey('onlyCoupon', 'Coupon')
					}

					if (!title) {
						title = getTranslatedTextByKey('webviewFlow.specialOffer', 'Special Offer!')
					}

					return title
				} catch (error) {
					return getTranslatedTextByKey('webviewFlow.specialOffer', 'Special Offer!')
				}
			}, [dealType, clickedFeaturedItem?.title, locale])

			useEffect(() => {
				if (clickedFeaturedItem) {
					clearTimeout(timeoutId)
					setBackdropEnabled(true)
					toggleItemPopup(true)
				} else if (!clickedFeaturedItem && isPopupOpen) {
					Home.setBackdropEnabled(false)
					window.history.replaceState(null, null, `${window.location.pathname}`)
					toggleItemPopup(false)
				}
			}, [clickedFeaturedItem])

			const onClosePopup = () => {
				couponFlowStore.setCouponToApply(null)
				window.history.replaceState(null, null, `${window.location.pathname}`)
				Home.setBackdropEnabled(false)
				toggleItemPopup(false)
				timeoutId = setTimeout(() => {
					setClickedFeaturedItem(null)
				}, 500)
			}

			return (
				<Container $isOpen={isPopupOpen} $newAddressBox={newAddressBox} $itemPopupTop={itemPopupTop} data-testid="item-popup-container">
					<ItemImage assetFullUrl={itemImage} asset={!itemImage && '/images/pastOrdersEmpty.png'} testId="item-image" />
					<TextContainer $itemPopupTop={itemPopupTop}>
						<StyledEmptyOrderText variant="h5" component="span">
							{getTranslatedTextByKey('webviewFlow.popupAddAddress', 'Add an address to get your')}
							{'	'}
							<ItemText variant="h5" component="span" data-testid="item-text">
								{itemTitle}
							</ItemText>
						</StyledEmptyOrderText>
					</TextContainer>
					{itemPopupTop ? (
						<CloseIconItemPupopTop
							id={`${Application.page}-page-banner-x-button`}
							onClick={onClosePopup}
							$show={isPopupOpen}
							testId={`${Application.page}-page-banner-x-button`}
						/>
					) : (
						<CloseIcon id={`${Application.page}-page-banner-x-button`} onClick={onClosePopup} $show={isPopupOpen} />
					)}
				</Container>
			)
		}
	)
)

export default ItemPopup
