// @ts-nocheck
import React from 'react'
import styled from 'styled-components'
import CartSummaryRow from './CartSummaryRow'
import CouponsList from 'components/common/coupons/CouponList'
import FeatureFlag from 'components/common/FeatureFlag'

const CartSummaryRowContainer = styled.div`
	padding: 20px;
	margin: 10px;
	box-shadow: var(--defaultShadow1);
	background: var(--clear);
	border-radius: var(--borderRadiusFrame);

	@media (max-width: 768px) {
		padding: 15px;
	}
`

const CouponsListContainer = styled.div`
	padding: 10px;
`

/**
 * Component for rendering ALL of the Cart's summary rows.
 *
 * This is used in the cart summary page AND in the confirmation page.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const CartSummaryRows = ({ cartItems, allowUpdate, isCheckout }) => (
	<>
		<CouponsListContainer data-testid="coupons-list-container">
			<CouponsList />
		</CouponsListContainer>

		{Object.keys(cartItems).map((_itemId, idx) =>
			cartItems[_itemId].map((_cartItem, cartItemIdx) => {
				const _key = `${_itemId}_${cartItemIdx}`

				return (
					<React.Fragment key={_key}>
						<CartSummaryRowContainer key={`${_key}_div`} $isCheckout={isCheckout} data-testid="cart-item-card">
							<CartSummaryRow
								key={_key}
								itemId={_itemId}
								cartItem={_cartItem}
								cartItemIdx={cartItemIdx}
								allowUpdate={allowUpdate}
								isCheckout={isCheckout}
							/>
						</CartSummaryRowContainer>
					</React.Fragment>
				)
			})
		)}
	</>
)

export default CartSummaryRows
