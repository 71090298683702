// @ts-nocheck
import React from 'react'
import styled, { css } from 'styled-components'
import TypographyPro from 'themes/TypographyPro'

const defaultTypeStyle = css`
	color: var(--primary);
	@media (hover: hover) and (pointer: fine) {
		:hover {
			color: var(--highlights);
		}
	}

	:active {
		transform: translate(1px, -1px);
		color: var(--highlights);

		@media (max-width: 576px) {
			:active {
				color: var(--primary);
			}
		}
	}

	${({ $disabled }) =>
		$disabled &&
		css`
			color: var(--disable);
			:hover,
			:active {
				color: var(--disable);
				transition: none;
				transform: none;
				cursor: default;
			}
		`}
`

const dangerTypeStyle = css`
	color: var(--err);
	@media (hover: hover) and (pointer: fine) {
		:hover {
			color: var(--err);
		}
	}

	:active {
		transform: translate(1px, -1px);
		color: var(--err);

		@media (max-width: 576px) {
			:active {
				color: var(--err);
			}
		}
	}

	${({ $disabled }) =>
		$disabled &&
		css`
			color: var(--disable);
			:hover,
			:active {
				color: var(--disable);
				transition: none;
				transform: none;
				cursor: default;
			}
		`}
`

const Text = styled(TypographyPro)`
	background: none;
	padding: 0;
	border: none;
	cursor: pointer;
	outline: inherit;
	transition: transform 0.5s, color 0.5s;

	${({ $type }) => ($type === 'danger' ? dangerTypeStyle : defaultTypeStyle)};
`

/**
 CTAText - call to action text
 props:
 $type - string (normal - default | danger)
 $size - string (small - default | big)
 */

const CTAText = ({ onClick, $type = 'default', disabled = false, children, $size = 'small', ...restProps }) => (
	<Text
		{...restProps}
		$disabled={disabled}
		disabled={disabled}
		onClick={disabled ? () => {} : onClick}
		variant={$size === 'big' ? 'CTABig' : 'CTASmall'}
		component="button"
		$type={$type}
		data-ctatext-type={$type}
		data-ctatext-size={$size}
	>
		{children}
	</Text>
)

export default CTAText
