// @ts-nocheck
import React from 'react'
import styled, { keyframes } from 'styled-components'
import MaterialSelect from '@material-ui/core/Select'
import SelectFieldArrowIcon from 'components/common/SelectFieldArrowIcon'
import InputLabelBase from 'components/common/InputLabel'
import TypographyPro from 'themes/TypographyPro'

const InputLabel = styled(TypographyPro)`
	color: var(--footerAndDarkBackgrounds);
`

const StyledSelect = styled(MaterialSelect)`
	background-color: #f3f3f3;
	border: 0;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	display: flex;
	align-items: center;
	border-radius: 5px;
	height: ${({ $fieldHeight }) => `${$fieldHeight}px`};
	color: #010101;
	.MuiSelect-root {
		padding-inline-start: 5px;
		:focus {
			background-color: unset;
		}
	}
`

// const StyledSelect = styled(MaterialSelect)({
// 	// fontFamily: ${({ theme }) => theme.typography.BodySmall.fontFamily,
// 	...themeJSON.typography.BodySmall,
// })

const rotateOpen = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
`

const rotateClose = keyframes`
  from {
	  transform: rotate(180deg);
  }

  to {
	  transform: rotate(0deg);
  }
`

const StyledSelectFieldArrowIcon = styled(SelectFieldArrowIcon)`
	margin: 5px;

	&.MuiSelect-iconOpen {
		animation: ${rotateOpen} 0.2s ease-in;
	}
	
	:not(.MuiSelect-iconOpen) {
		animation: ${rotateClose} 0.2s ease-in;
	}
	
}
`

const Select = ({ children, fieldHeight, label, ...selectProps }) => (
	<div>
		<InputLabel variant="BodyRegularHighlighted">{label}</InputLabel>
		<StyledSelect
			inputProps={{
				'data-testid': 'selected-option',
			}}
			$fieldHeight={fieldHeight}
			{...selectProps}
			IconComponent={StyledSelectFieldArrowIcon}
		>
			{children}
		</StyledSelect>
	</div>
)

export default Select
