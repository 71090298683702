// @ts-nocheck
import React from 'react'
import IconComponent from '../../themes/IconComponent'

// assets
const ArrowIcon = (props) => <IconComponent asset="/icons/selectFieldArrow.svg" {...props} strokeOrFill="stroke" />

const SelectFieldArrowIcon = (props) => <ArrowIcon {...props} />

export default SelectFieldArrowIcon
