import { useEffect, useState } from 'react'

export const useMutationObserver = (targetNodeStr: string) => {
	const [node, setNode] = useState<Element | null>(null)

	useEffect(() => {
		if (!targetNodeStr) {
			return
		}

		const observer = new MutationObserver((mutationsList, observer) => {
			for (const mutation of mutationsList) {
				if (mutation.type === 'childList') {
					const target = document.querySelector(targetNodeStr)
					if (target) {
						setNode(target)
						observer.disconnect()
					}
				}
			}
		})

		observer.observe(document.body, { childList: true, subtree: true })

		return () => observer?.disconnect()
	}, [targetNodeStr])

	return node
}
