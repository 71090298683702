import { useContext, useEffect, useState } from 'react'
import { formatPrice, routeToPage } from 'utils/utils'
import type StoreContextInterface from 'types/StoreContext'
import type { CartType, CouponDiscount, CouponListItem, InfraType, SnackBarMessageType } from './types'
import type { LanguageLocale } from 'utils/utils'
import { StoreContext } from 'contexts/StoreContext'
import { differenceBy, intersectionBy } from 'lodash'
import { Page } from 'utils/constants'
import { isEmpty } from 'lodash-es'
import { useStores } from 'hooks/useStores'

const useCouponList = ({ discounts, serverCharges, getTotalPriceInCart }: CartType, Infra: InfraType) => {
	const {
		store: { data: rest },
	} = useContext(StoreContext) as StoreContextInterface
	const [couponList, setCouponList] = useState<CouponListItem[]>([])
	const cartTotalPrice: number = getTotalPriceInCart()
	const { couponsStore } = useStores()

	const createCouponListItem = (
		coupon: CouponDiscount,
		active = false,
		amountLeftToGetDiscount = '',
		amount = '',
		locale: LanguageLocale = 'en_US'
	): CouponListItem => ({
		code: coupon.code,
		coupon,
		status: active ? 'success' : 'warning',
		amountLeftToGetDiscount,
		amount,
		locale,
	})

	const showSnackbar = (message: SnackBarMessageType, status: 'success' | 'warning') => {
		Infra.showSnackbar({
			key: 'cart',
			snackId: 'cart',
			status,
			message,
			isAttachedToElement: routeToPage(location.pathname) === Page.MENU,
		})
	}

	useEffect(() => {
		if (isEmpty(discounts) || isEmpty(rest)) {
			setCouponList([])
			return
		}

		const discountsArr = Object.values(discounts)
		// get coupons that exist in both discounts and serverCharges
		const matchDiscounts = intersectionBy(discountsArr, serverCharges, 'code')
		const newCouponList = createCouponList()

		if (matchDiscounts.length && newCouponList.length) {
			matchDiscounts.forEach((coupon) => {
				const amountFromServerCharges = serverCharges.find((chargedItem) => chargedItem.code === coupon.code)?.amount
				const idx = newCouponList.findIndex((cpnItem) => cpnItem.coupon.code === coupon.code)
				const formattedAmountPrice = formatPrice(amountFromServerCharges, rest.currency, rest.countryCode, 1)
				const couponToSave = createCouponListItem(coupon, true, '', formattedAmountPrice)

				newCouponList.splice(idx, 1, couponToSave)
			})
		}

		const updatedDiscounts = newCouponList.map(({ coupon }) => {
			// checking the amount that left using serverCharges (especially for minOrderCoupons)
			const hasCouponActivated = serverCharges.some((serverChargesItem) => serverChargesItem.code === coupon.code)
			const amountFromServerCharges = serverCharges.find((chargedItem) => chargedItem.code === coupon.code)?.amount
			const formattedAmountPrice = hasCouponActivated ? formatPrice(amountFromServerCharges, rest.currency, rest.countryCode, 1) : ''

			const amountLeftToGetDiscount = discounts[coupon.code].minOrderPrice - cartTotalPrice
			const formattedAmountLeftToGetDiscount = hasCouponActivated
				? ''
				: formatPrice(amountLeftToGetDiscount, rest.currency, rest.countryCode, 1)
			const couponListing = createCouponListItem(
				discounts[coupon.code],
				hasCouponActivated,
				formattedAmountLeftToGetDiscount,
				formattedAmountPrice,
				rest.locale
			)

			return couponListing
		})

		setCouponList(updatedDiscounts)
	}, [serverCharges, discounts, rest])

	const createCouponList = (): CouponListItem[] => {
		const discountsArr = Object.values(discounts)
		// get coupons that exist in discount but not in couponList
		const uniqueInDiscounts: CouponDiscount[] = differenceBy(discountsArr, couponList, 'code')

		const newCoupons = uniqueInDiscounts.map((coupon) => {
			let formattedAmountLeftToGetDiscount = '0.00'
			if (coupon?.minOrderPrice) {
				const amountLeftToGetDiscount = coupon.minOrderPrice - cartTotalPrice
				formattedAmountLeftToGetDiscount = formatPrice(amountLeftToGetDiscount, rest?.currency, rest?.countryCode, 1)
			}

			return createCouponListItem(coupon, false, formattedAmountLeftToGetDiscount)
		})

		// Exclude coupons that have been removed from the cart
		return [...couponList, ...newCoupons].filter((c) => discounts[c.code])
	}

	return {
		list: couponList,
	}
}

export default useCouponList
