// @ts-nocheck
import React, { useState, useEffect, useRef, useMemo } from 'react'
import ScrollableTabs from 'components/menu/menuSideBar/ScrollableTabs'
import { inject, observer } from 'mobx-react'
import { useMediaQuery } from 'react-responsive'
import { mediaQueriesBreakpoints } from 'styles/mixins'
import styled from 'styled-components'
import NewHeaderLayout from 'components/home/NewHomePageHeader/NewHeaderLayout'
import LocalizedHeader from './headers/LocalizedHeaderBar/LocalizedHeader'
import BackdropBase from '@material-ui/core/Backdrop'
import Collapse from '@material-ui/core/Collapse'

const allowedPaths = ['/menu']

const Backdrop = styled(BackdropBase)`
	z-index: 1200;
	background-color: rgba(0, 0, 0, 0.6);
`

const MOBILE_THRESHOLD_SCROLL_SIZE = 100
const DESKTOP_THRESHOLD_SCROLL_SIZE = 200

const SubHeaderGroup = inject(
	'User',
	'Infra',
	'Home'
)(
	observer(({ User, Home, showStoreInfo, showMenuTabs, children, page, Infra, isGeneratedStatically, deliveryInfo, storeData }) => {
		const [subHeaderHeight, setSubHeaderHeight] = useState(0)
		const subHeaderRef = useRef(null)
		const isMobile = !useMediaQuery({ query: mediaQueriesBreakpoints.sm })
		const [show, setShow] = useState(true)
		const thresholdSize = isMobile ? MOBILE_THRESHOLD_SCROLL_SIZE : DESKTOP_THRESHOLD_SCROLL_SIZE

		const handleScroll = () => {
			if (window.scrollY <= 0) {
				setShow(true)
			} else if (window.scrollY > thresholdSize) {
				setShow(false)
			}
		}

		useEffect(() => {
			if (
				(Infra?.appParams?.features?.staticPagesKeepSubHeaderOnScroll?.length === 0 ||
					!Infra?.appParams?.features?.staticPagesKeepSubHeaderOnScroll?.includes(page)) &&
				!Home?.backdropEnabled
			) {
				window.addEventListener('scroll', handleScroll)
				return () => {
					window.removeEventListener('scroll', handleScroll)
				}
			}
		}, [])

		useEffect(() => {
			if (subHeaderRef?.current) {
				setSubHeaderHeight(subHeaderRef.current.clientHeight)
			}
		}, [subHeaderRef?.current, Home?.branchesList?.msg, Home.locale])

		const shouldShowOnCurrentPage = () => allowedPaths.some((path) => location.pathname.includes(path))

		const showAddressBox = useMemo(
			() => Infra?.appParams?.features?.staticPagesWithAddressBox?.includes(page || window.location?.pathname?.split('/')[1]),
			[Infra?.appParams?.features?.staticPagesWithAddressBox, page]
		)

		const getHeaderOffset = () => {
			const baseOffset = isMobile ? 50 : 70
			return show ? subHeaderHeight + baseOffset : baseOffset
		}

		return (
			<div>
				<Collapse in={show}>
					<div ref={subHeaderRef}>
						{children}
						{showAddressBox && <NewHeaderLayout page={page || window.location?.pathname?.split('/')[1]} isGeneratedStatically />}
						{!showAddressBox && !isGeneratedStatically && showStoreInfo && deliveryInfo && shouldShowOnCurrentPage() && (
							<LocalizedHeader locale={User.session.locale} storeData={storeData} deliveryInfo={deliveryInfo} isLocalizedBar />
						)}
					</div>
					{showAddressBox && <Backdrop open={Home?.backdropEnabled} id="sub-header-backdrop" />}
				</Collapse>

				{showMenuTabs && <ScrollableTabs headerOffset={getHeaderOffset()} />}
			</div>
		)
	})
)

export default SubHeaderGroup
