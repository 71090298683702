// @ts-nocheck
import React, { useEffect } from 'react'
import Infra from '../mobx/Infra'

// todo zohar : maybe dont use a variadic function (maybe use ids that would be an array
const useSnackbar = (elRef, ...ids) => {
	useEffect(() => {
		if (elRef.current) {
			Infra.addSnackbar(ids, elRef)

			return function cleanup() {
				Infra.clearSnackbars(ids)
			}
		}
	}, [])

	return {}
}

export default useSnackbar
