// @ts-nocheck
import React from 'react'
import styled from 'styled-components/macro'
import LanguageSelector from '../../../static/components/StoreLocator/LanguageSelector'
import { inject, observer } from 'mobx-react'

const MenuLanguage = styled.div`
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	margin: 5px 0 30px 0;
`

const SideMenuLanguageSelector = inject('Infra')(
	observer((props) => {
		const { Infra } = props
		const languages = Infra.appParams?.languages
		return (
			!!languages &&
			languages.length > 1 && (
				<MenuLanguage>
					<LanguageSelector isGeneratedStatically={false} languages={languages} fontColor="#000000" />
				</MenuLanguage>
			)
		)
	})
)

export default SideMenuLanguageSelector
