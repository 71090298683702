// @ts-nocheck
import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import IconComponent from '../../themes/IconComponent'

const HomepageMap = (props) => <IconComponent asset="/icons/homepageMapIcon.svg" {...props} />

const SvgHomepageMapIcon = (props) => <SvgIcon component={HomepageMap} viewBox="0 0 32 32" {...props} />

export default SvgHomepageMapIcon
