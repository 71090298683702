// @ts-nocheck
import React from 'react'
import styled from 'styled-components/macro'
import IconComponent from '../../../themes/IconComponent'

import { inject, observer } from 'mobx-react'
import TypographyPro from '../../../themes/TypographyPro'
import SmartLink from '../../../static/components/SmartLink'
import { toJS } from 'mobx'

import Collapse from '@material-ui/core/Collapse'
import { spaceToDash } from 'utils/utils'

const ArrowIcon = (props) => <IconComponent asset="/icons/rightArrow.svg" {...props} />
const MenuIcon = (props) => <IconComponent {...props} />

const StyledMenuIcon = styled(MenuIcon)`
	width: 17px;
	height: 17px;
	margin-right: 5px;
`

const StyledTitle = styled.div`
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
`

const StyledSection = styled.section`
	border-top: 1px solid #e9ebee;
	border-bottom: 1px solid #e9ebee;
	padding: 10px 0;
`

const ExpandableHeader = styled.article`
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	margin-bottom: ${(props) => (props.expanded ? '8px' : '0')};
	transition: margin-bottom 0.3s ease-in-out;
`

const StyledArrowIcon = styled(ArrowIcon)`
	width: 12px;
	height: 12px;
	transform: ${(props) => (props.expanded ? 'rotateZ(-90deg)' : 'rotateZ(90deg)')};
	transition: transform 0.3s;
`

const ExpandableTitle = styled(TypographyPro)`
	opacity: ${(props) => (props.disabled ? 0.4 : 1)};
`

const StyledSideMenuLink = styled.div`
	color: black;
	padding: 5px 0 5px 10px;
`

const SideMenuExpandable = inject(
	'Infra',
	'User'
)(
	observer((props) => {
		const { Infra, User } = props
		const language = User.preferredLanguage || Infra.appParams.l

		const closeMenu = () => {
			Infra.toggleMobileMenu()
		}

		return (
			<StyledSection data-testid="side-menu-expandable-container">
				<ExpandableHeader
					expanded={props.expanded}
					onClick={() => {
						if (props.disabled) {
							return
						}
						props.setExpanded((ps) => (ps === props.section.sectionId ? '' : props.section.sectionId))
					}}
					data-testid="hamburger-expand-footer"
				>
					<StyledTitle>
						{props.section.icon && <StyledMenuIcon asset={`/icons/${props.section.icon}`} />}
						<ExpandableTitle disabled={props.disabled} variant="BodyRegular">
							{props.section.name}
						</ExpandableTitle>
					</StyledTitle>
					{!props.disabled && (
						<div>
							<StyledArrowIcon expanded={props.expanded} />
						</div>
					)}
				</ExpandableHeader>
				<Collapse in={props.expanded} timeout="auto">
					{props.section.items &&
						props.section.items.map((sectionItem) => {
							if (sectionItem.itemType === 'regularLink') {
								return (
									<SmartLink
										sectionItem={toJS(sectionItem)}
										key={sectionItem.itemId}
										isGeneratedStatically={props.isGeneratedStatically}
										callbackClick={closeMenu}
										openInSameBrowserTab={sectionItem.itemType !== 'external'}
									>
										<StyledSideMenuLink>
											<TypographyPro variant="BodyRegular">{sectionItem.text}</TypographyPro>
										</StyledSideMenuLink>
									</SmartLink>
								)
							}
							return (
								<SmartLink
									sectionItem={toJS(sectionItem)}
									key={sectionItem.itemId}
									isGeneratedStatically={props.isGeneratedStatically}
									callbackClick={closeMenu}
									openInSameBrowserTab={sectionItem.itemType !== 'external'}
								>
									<StyledSideMenuLink>
										<TypographyPro
											variant="BodyRegular"
											data-testid={`side-menu-link-${spaceToDash(sectionItem.text?.toLowerCase())}`}
										>
											{sectionItem.text}
										</TypographyPro>
									</StyledSideMenuLink>
								</SmartLink>
							)
						})}
				</Collapse>
			</StyledSection>
		)
	})
)

export default SideMenuExpandable
