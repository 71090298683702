// @ts-nocheck
/**
 * Function getElementByIdWrapper provides a fallback in case an element does not exist
 * Example on how to call it:
 * 		getElementByIdWrapper('appBarHeader', (element) => {
 *   		element.style.display = 'none'
 * 		})
 * Note: getElementById should be avoided as much as we can
 */
export default function getElementByIdWrapper(id, callback) {
	const element = document.getElementById(id)
	if (element) {
		callback(element)
	} else {
		console.error(`Error: element ${id} not part of the DOM`)
	}
}
