// @ts-nocheck
import React from 'react'
import styled, { css } from 'styled-components/macro'
import { getTranslatedTextByKey, isMobile } from 'utils/utils'
import IconComponent from 'themes/IconComponent'
import TypographyPro from 'themes/TypographyPro'

const isMobileView = isMobile()

/** 
 	FYI : There is a deal.svg file (in Snackbar.jsx) that is identical to coupon.svg (more specifically Taco Bell brand).
	Currently, it is used inside "coupon successfully applied" message for all brands and in case the icon should be "branded" we should change it from deal.svg to coupon.svg.
	In the mean wile it provides more flexibility 
*/
const CouponIcon = (props) => <IconComponent asset="/icons/coupon.svg" viewBox="0 0 21 21" {...props} />
const CouponIconContainer = styled.div`
	max-width: 35px;
	padding-right: 10px;
	display: flex;
`
const CouponMessage = styled(TypographyPro)`
	display: flex;
	align-items: center;

	${({ $isMobileView }) =>
		$isMobileView
			? css`
					font-size: 14px;
					line-height: 17px;
			  `
			: css`
					font-size: 16px;
					line-height: 19px;
			  `}
`
const CouponOnNextStepContainer = styled.div`
	align-items: center;
	border-radius: 4px;
	display: flex;
	color: var(--fonts);
`
const CouponCodeOnNextStep = () => (
	<CouponOnNextStepContainer>
		<CouponIconContainer>
			<CouponIcon />
		</CouponIconContainer>
		<CouponMessage variant="BodyRegular" $isMobileView={isMobileView}>
			{getTranslatedTextByKey('couponOnNextPage')}
		</CouponMessage>
	</CouponOnNextStepContainer>
)

export default CouponCodeOnNextStep
