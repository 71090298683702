// @ts-nocheck
import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { inject, observer } from 'mobx-react'
import { THEME_STRATEGY } from 'constants/theme'
import { getThemeUrl } from 'utils/theme/themeManager'

const LoadingSpinner = inject('Infra')(
	observer(({ chainId, brand, Infra, fileName = 'spinner.gif' }) => {
		if (chainId && brand && Infra?.appParams?.theme) {
			const themeUrl = getThemeUrl(Infra.appParams.theme || THEME_STRATEGY.GENERIC, chainId, brand)
			return <img alt="Loading" src={`${themeUrl}/assets/loading/${fileName}`} data-testid="loader" />
		}

		return <CircularProgress color="primary" />
	})
)

export default LoadingSpinner
