// @ts-nocheck
import React from 'react'
import styled from 'styled-components'
import LoadingSpinner from './LoadingSpinner'
import Backdrop from '@material-ui/core/Backdrop'

const StyledBackdrop = styled(Backdrop)`
	z-index: ${({ $isMenuAutoLoad }) => ($isMenuAutoLoad ? 9999 : 99999999)};
	color: #fff;
	background: ${({ $isMenuAutoLoad }) => ($isMenuAutoLoad ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0.6)')};
`

const BackdropAndLoader = (props) => (
	<StyledBackdrop $isMenuAutoLoad={props.isMenuAutoLoad} open={props.open}>
		<LoadingSpinner chainId={props.chainId} brand={props.brand} />
	</StyledBackdrop>
)

export default BackdropAndLoader
