// @ts-nocheck
import React from 'react'
import styled from 'styled-components'
import { getTranslatedTextByKey } from 'utils/utils'
import TypographyPro from '../../themes/TypographyPro'

const TaxDisclaimerWrapper = styled(TypographyPro)`
	line-height: 16px;
	margin-top: 5px;
	color: var(--fonts);
`
const TaxDisclaimer = () => (
	<TaxDisclaimerWrapper variant="BodySmall">
		{getTranslatedTextByKey('webviewFlow.taxDisclaimer', '*Tax will be calculated at checkout')}
	</TaxDisclaimerWrapper>
)

export default TaxDisclaimer
