// @ts-nocheck
import React from 'react'
import cx from 'classnames'
import styled from 'styled-components'

const ErrorMessageContainer = styled.div`
	margin-top: ${({ noMargin }) => (noMargin ? 'unset' : '10px')};
	text-align: left;
	color: var(--err);
	display: flex;
	align-items: center;
	${({ theme }) => theme.typography.BodySmall};
`

const ErrorMessage = ({ message, className, noMargin }) => (
	<ErrorMessageContainer className={cx(className)} noMargin={noMargin}>
		{message}
	</ErrorMessageContainer>
)

export default ErrorMessage
