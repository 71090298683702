// @ts-nocheck
import React from 'react'
import versionInfo from '../../version.json'
import styled from 'styled-components'
import TypographyPro from '../themes/TypographyPro'

const StyledVersion = styled(TypographyPro)`
	color: ${({ $color }) => $color};
	height: 30px;
`

const { version } = versionInfo

const Version = ({ color }) => (
	<StyledVersion component="div" variant="BodySmall" $color={color || 'black'} data-testid="version-number-text">
		<b>version</b> {version}&nbsp;{/* <b>build-date</b>: {build_date} */} (dpr: {window.devicePixelRatio})
	</StyledVersion>
)

export default Version
