// @ts-nocheck
import React from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

import Grid from '@material-ui/core/Grid'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import BackIcon from '@material-ui/icons/ArrowBackIos'
import TypographyPro from 'themes/TypographyPro'

import { SIGN_UP_STEPS } from '../../constants/signUpProcess'
import { getTranslatedTextByKey } from 'utils/utils'

const StyledAppBar = styled(AppBar)`
	background-color: white;
	box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
`

const StyledTypography = styled(TypographyPro)`
	text-align: center;
	align-items: center;
	width: 100%;
	color: black;
	text-transform: uppercase;
`

const StyledGrid = styled(Grid)`
	height: 50px;
`

const AccountMobileHeader = inject(
	'Account',
	'MobileApplication'
)(
	observer(({ Account, onClose }) => {
		const { signUpStep, moveToSignUpStep } = Account

		return (
			<StyledGrid item xs={12}>
				<StyledAppBar position="static">
					<Toolbar variant="dense">
						<Grid style={{ width: '48px' }}>
							{signUpStep === SIGN_UP_STEPS.EDIT_CODE && (
								<IconButton
									onClick={() => {
										moveToSignUpStep(SIGN_UP_STEPS.EDIT_PHONE_NUMBER)
									}}
									edge="end"
									aria-label="close"
								>
									<BackIcon />
								</IconButton>
							)}
						</Grid>

						<StyledTypography variant="h5">{getTranslatedTextByKey('eCommerce.signIn.title')}</StyledTypography>

						<IconButton onClick={onClose} edge="start" aria-label="menu">
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</StyledAppBar>
			</StyledGrid>
		)
	})
)

export default AccountMobileHeader
