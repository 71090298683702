// @ts-nocheck
import React from 'react'
import styled from 'styled-components'
import Divider from '@material-ui/core/Divider'

const StyledDivider = styled(Divider)`
	width: 100%;
	height: 1px;
	background-color: var(--disable);
	align-self: center;
`

export default ({ className = {} }) => <StyledDivider className={className} />
