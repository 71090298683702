// @ts-nocheck
import React, { useEffect, useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { CONSTANTS, ORDER_TYPES } from 'utils/constants'
import { getTranslatedTextByKey } from 'utils/utils'
import styled, { css } from 'styled-components/macro'
import ButtonBase from 'components/common/ButtonBase'
import TypographyPro from 'themes/TypographyPro'
import { sendCustomEvent } from 'utils/analytics/analytics'
import queryString from 'query-string'
import { useStores } from '../../../hooks/useStores'
import { OfferType, OrderType } from '../../../types/Coupons'

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
`

const OrderTypeText = styled(TypographyPro)`
	text-transform: uppercase;
	color: var(--strokeMenuTitle);
	text-align: center;
`

const Button = styled(ButtonBase)`
	background-color: var(--clear);
	box-shadow: var(--innerShadow);
	border: unset !important;
	padding: 0 5px;

	&:hover {
		box-shadow: var(--innerShadow);
	}

	${({ $isOrderTypeSelected }) =>
		$isOrderTypeSelected &&
		css`
			background: var(--primary);
			box-shadow: var(--highlightShadow2);

			${OrderTypeText} {
				color: var(--clear);
			}

			&:hover {
				box-shadow: var(--highlightShadow2) !important;
				background-color: var(--primary) !important;
			}
			&:active {
				box-shadow: var(--highlightShadow2) !important;
				background-color: var(--secondary) !important;
			}
		`}
`

const OrderTypes = inject(
	'Home',
	'User',
	'Application',
	'Infra'
)(
	observer((props) => {
		const { couponFlowStore } = useStores()

		const { Home, User, Application, Infra } = props
		const { branchesList, setClickedFeaturedItem, clickedFeaturedItem } = Home

		const isCurrentOrderTypeDelivery = useMemo(() => User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY, [User.orderType])

		const handleChange = (newValue) => {
			if (clickedFeaturedItem) {
				const keepModalOpened = couponFlowStore.isCouponToApplyAvailableFor(
					newValue === CONSTANTS.DELIVERY_METHODS.DELIVERY ? OrderType.DELIVERY : OrderType.PICKUP
				)

				if (!keepModalOpened) {
					const { couponToApply } = couponFlowStore
					const type = couponToApply?.coupon?.offerType === OfferType.ITEM ? 'item' : 'discount'
					const title =
						type === 'item'
							? getTranslatedTextByKey(
									'eCommerce.coupons.error.itemNotAvailableForOrderType',
									'The item is not available for the selected order type'
							  )
							: getTranslatedTextByKey(
									'eCommerce.coupons.error.couponNotAvailableForOrderType',
									'The coupon is not available for the selected order type'
							  )
					setClickedFeaturedItem(null)

					couponFlowStore.clearCouponToApply()

					Infra.setNotification({
						open: true,
						title,
						onClose: () => {
							Infra.closeNotification()
						},
						okAction: () => {
							Infra.closeNotification()
						},
					})
				}
			}

			switch (newValue) {
				case CONSTANTS.DELIVERY_METHODS.DELIVERY:
					User.setOrderType(CONSTANTS.DELIVERY_METHODS.DELIVERY)
					break
				case CONSTANTS.DELIVERY_METHODS.PICKUP:
					User.setOrderType(CONSTANTS.DELIVERY_METHODS.PICKUP)
					break
				default:
					console.error(`Unknown delivery method tab clicked: ${newValue}`)
			}

			sendCustomEvent({
				category: 'address box',
				action: 'button clicked',
				label: newValue === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PICKUP,
			})

			User.setOrderType(newValue)
		}

		useEffect(() => {
			const queryParams = queryString.parse(window.location.search)
			const { ot } = queryParams
			if (ot) {
				User.setOrderType(ot)
			} else if (!branchesList.error && branchesList.msg) {
				if (branchesList.msg?.deliveryStores && !branchesList.msg?.disablePickup) {
					User.setOrderType(User.getOrderType() ?? CONSTANTS.DELIVERY_METHODS.PICKUP)
				} else if (!branchesList.msg?.deliveryStores && !branchesList.msg?.disablePickup) {
					User.setOrderType(CONSTANTS.DELIVERY_METHODS.PICKUP)
				} else if (branchesList.msg?.deliveryStores && branchesList.msg?.disablePickup) {
					User.setOrderType(CONSTANTS.DELIVERY_METHODS.DELIVERY)
				} else {
					User.setOrderType(CONSTANTS.DELIVERY_METHODS.DELIVERY)
				}
			}
		}, [branchesList.msg])

		return (
			<Container>
				{Home.branchesList.msg?.deliveryStores && (
					<Button
						$type={isCurrentOrderTypeDelivery ? 'primary' : 'secondary'}
						data-testid="delivery-tab"
						id={`${Application.page}-page-delivery-button`}
						onClick={() => handleChange(CONSTANTS.DELIVERY_METHODS.DELIVERY)}
						$isOrderTypeSelected={isCurrentOrderTypeDelivery}
					>
						<OrderTypeText variant="h5">{getTranslatedTextByKey(ORDER_TYPES.DELIVERY, 'Delivery')}</OrderTypeText>
					</Button>
				)}
				{!Home.branchesList.msg?.disablePickup && (
					<Button
						$type={!isCurrentOrderTypeDelivery ? 'primary' : 'secondary'}
						data-testid="pickup-tab"
						id={`${Application.page}-page-pickup-button`}
						onClick={() => handleChange(CONSTANTS.DELIVERY_METHODS.PICKUP)}
						$isOrderTypeSelected={!isCurrentOrderTypeDelivery}
					>
						<OrderTypeText variant="h5">{getTranslatedTextByKey(ORDER_TYPES.PEAKUP, 'Pickup')}</OrderTypeText>
					</Button>
				)}
			</Container>
		)
	})
)

export default OrderTypes
