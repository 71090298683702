// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { getRandomUUID, sendRequest } from '../../../utils/utils'
import NativeSelect from '@material-ui/core/NativeSelect'
import { ReloadContext } from '../../../contexts/ReloadContext'
import oStorage from '../../../utils/o-storage'
import { CONSTANTS } from '../../../utils/constants'
import PropTypes from 'prop-types'
import Infra from 'mobx/Infra'
import User from 'mobx/User'

const StyledSelect = styled(NativeSelect)`
	padding: 4px 8px 2px 30px;
	background-color: transparent;
	border-radius: 34px;
	color: ${(props) => props.$fontcolor};
	border: 1px solid #e9ebee;
	height: 36px;
	width: 91px;

	@media (max-width: 576px) {
		width: 89px;
	}

	& .MuiNativeSelect-icon {
		color: ${(props) => props.$fontcolor};
	}

	select {
		text-transform: uppercase;
		color: ${(props) => props.$fontcolor};
		${({ theme }) => theme.typography.BodyRegularHighlighted};
	}

	svg {
		margin-inline-end: 15px;
	}
`

const StyledOption = styled.option`
	background-color: var(--footerAndDarkBackgrounds); !important;
	color: var(--fonts);
`

/**
 * Allow the user to change language (taken from params.json)
 *
 * @param props - must include an array of language strings eg ["de", "en"]
 * @returns {*}
 * @constructor
 */
const LanguageSelector = (props) => {
	const [language, setLanguage] = useState('')
	const { setKey } = useContext(ReloadContext)

	// footer = applies footer colors
	const { fontColor } = props

	// if empty languages array then use 'en' as the default
	const _languages = props.languages && props.languages.length > 0 ? props.languages : ['en']

	const getLanguageFromURL = () => {
		const [, firstArgument] = window.location.pathname.split('/')
		const poolOfRecognizedLanguages = ['ar', 'en', 'de', 'es', 'fr', 'iw', 'pap', 'pt', 'ru']
		const isFirstArgumentLanguage = firstArgument && poolOfRecognizedLanguages.includes(firstArgument)

		return isFirstArgumentLanguage ? firstArgument : null
	}

	const updateCurrentLanguage = async (lang = Infra.appParams.l) => {
		await User.setLanguages(lang, Infra.appParams.c)

		const footerData = await sendRequest(false, `${Infra.appParams.wruec}/v1/ecommerce-footer?chainId=${Infra.appParams.c}&lang=${lang}`, 'get')

		Infra.setECommerceFooter(footerData)

		const languageFromUrl = getLanguageFromURL()
		if (languageFromUrl) {
			const newPath = window.location.pathname.replace(`/${languageFromUrl}/`, `/${lang}/`)

			window.history.replaceState(null, '', `${newPath}${window.location.search}`)
		}

		setLanguage(lang)

		setKey(`${getRandomUUID()}`)
	}

	useEffect(() => {
		const currentLanguage = getLanguageFromURL() || oStorage.get(CONSTANTS.USER.PREFERRED_LANGUAGE) || Infra.appParams.l
		updateCurrentLanguage(currentLanguage)
	}, [])

	const handleChange = async (event) => {
		const currentLanguage = event.target.value

		if (currentLanguage !== language) {
			await updateCurrentLanguage(currentLanguage)

			oStorage.set(CONSTANTS.USER.PREFERRED_LANGUAGE, currentLanguage)
		}
	}

	return (
		!!language && (
			<StyledSelect
				value={language}
				onChange={handleChange}
				$fontcolor={fontColor}
				name="language"
				disableUnderline
				inputProps={{ 'data-testid': 'language-selector' }}
			>
				{_languages.map((_language) => (
					<StyledOption key={_language} value={_language} data-testid={_language}>
						{_language?.toUpperCase()}
					</StyledOption>
				))}
			</StyledSelect>
		)
	)
}

LanguageSelector.propTypes = {
	languages: PropTypes.arrayOf(PropTypes.string),
}

export default LanguageSelector
