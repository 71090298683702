import type { ReactElement } from 'react'
import React from 'react'
import styled from 'styled-components/macro'
import IconComponent from 'themes/IconComponent'
import SmartLink from 'static/components/SmartLink'
import { respondAbove } from 'styles/mixins'
import { inject, observer } from 'mobx-react'
import type _Infra from 'mobx/Infra'
import { useRouter } from 'next/router'

const PinIcon = styled(IconComponent).attrs({
	asset: '/icons/locationPinHeader.svg',
})`
	height: 18px;
	width: 18px;
	margin-inline-end: 5px;
`

const EditIcon = styled(IconComponent).attrs({
	asset: '/icons/edit-localized.svg',
})`
	height: 25px;
	width: 25px;
	margin-inline-start: 20px;
`

const Address = styled.div`
	color: var(--strokeMenuTitle);
	display: grid;
	grid-template-columns: auto auto;
`

const Container = styled.div`
	display: grid;
	grid-template-columns: auto auto auto;
	align-items: center;
	cursor: pointer;

	${respondAbove.md`
		padding: 8px;
	`};

	${respondAbove.lg`
		border: 1px solid var(--highlights);
	`}
`

const Layout = styled.div`
	padding: 15px 20px 10px 20px;

	${respondAbove.md`
		padding: 15px 40px 10px 40px;
	`}

	${respondAbove.lg`
		padding: 0 40px 10px 40px;
	`}
`

interface LocalizedAddressBarProps {
	isGeneratedStatically: boolean
	address: ReactElement
	handleLocalizedAddress: () => void
	Infra?: typeof _Infra
	page: string
}

const LocalizedAddressBar = ({
	address,
	isGeneratedStatically,
	handleLocalizedAddress,
	Infra = {} as typeof _Infra,
	page,
}: LocalizedAddressBarProps) => {
	const router = useRouter()

	const handleEdit = () => {
		if (isGeneratedStatically || Infra?.appParams?.features?.staticPagesWithAddressBox?.includes(page)) {
			handleLocalizedAddress()
			return
		}

		if (!history) {
			SmartLink.route(
				`/?modifyLocalizedAddress=true`,
				router,
				(window as Window & typeof global & { isGeneratedStatically: boolean }).isGeneratedStatically,
				''
			)
		} else {
			router.push({
				pathname: '/',
				search: `?modifyLocalizedAddress=true`,
			})
		}
	}

	return (
		<Layout>
			<Container onClick={handleEdit}>
				<PinIcon />
				<Address>{address}</Address>
				<EditIcon testId="localized-edit-icon" />
			</Container>
		</Layout>
	)
}

export default inject('Infra')(observer(LocalizedAddressBar))
