// @ts-nocheck
import React from 'react'
import IconComponent from '../../../themes/IconComponent'
import styled from 'styled-components/macro'

const CloseIcon = (props) => <IconComponent asset="/icons/close.svg" {...props} />

const MobileHeader = styled.div`
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 30px;
`

const StyledLogo = styled.div`
	width: 100vw;
	height: 100vh;
	max-width: 106px;
	max-height: 43px;
	object-fit: contain;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	background-image: ${({ $logoUrl }) => `url('${$logoUrl}')`};
	flex-grow: 1;
	cursor: pointer;
`

const StyledCloseIcon = styled(CloseIcon)`
	width: 19px;
	height: 19px;
	margin-inline-start: 11px;
	cursor: pointer;
`

const SideMenuHeader = (props) => {
	const { Infra } = props
	const logoMobileHeader = Infra.appParams.eCommerce
		? Infra?.appParams?.assets?.logoMobileHeader
		: props?.headerProps?.logoURL || Infra.appParams.logo
	return (
		<MobileHeader>
			<div style={{ width: '25px' }} data-testid="side-menu-close">
				<StyledCloseIcon onClick={() => props.closeMenu()} />
			</div>
			<StyledLogo $logoUrl={logoMobileHeader} data-testid="side-menu-logo" />
			<div style={{ width: '25px' }} />
		</MobileHeader>
	)
}

export default SideMenuHeader
