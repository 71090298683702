// @ts-nocheck
import React from 'react'
import styled from 'styled-components/macro'
import IconComponent from '../../../themes/IconComponent'
import { inject, observer } from 'mobx-react'
import TypographyPro from '../../../themes/TypographyPro'
import { Collapse } from '@material-ui/core'

const ArrowIcon = (props) => <IconComponent asset="/icons/rightArrow.svg" {...props} />

const StyledSection = styled.section`
	border-top: 1px solid #e9ebee;
	border-bottom: 1px solid #e9ebee;
	padding: 22.5px 0;
`

const ExpandableHeader = styled.article`
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	color: var(--strokeMenuTitle);
	margin-bottom: ${(props) => (props.expanded ? '15px' : '0')};
`

const StyledArrowIcon = styled(ArrowIcon)`
	width: 12px;
	height: 12px;
	transform: ${(props) => (props.expanded ? 'rotateZ(-90deg)' : 'rotateZ(90deg)')};
	transition: transform 0.3s;
`

const ExpandableContent = styled.article`
	padding: 8px 0 8px 0;
	color: black;
`

const SideMenuStoreSectionExpandable = inject('Infra')(
	observer((props) => {
		const expanded = props.expanded === props.title
		return (
			<StyledSection>
				<ExpandableHeader
					expanded={expanded}
					onClick={() => props.setExpanded((ps) => (ps === props.title ? '' : props.title))}
					data-testid="hamburger-expanded-header"
				>
					<TypographyPro variant="h5">{props.title}</TypographyPro>
					<div>
						<StyledArrowIcon expanded={expanded} />
					</div>
				</ExpandableHeader>
				<Collapse in={expanded} timeout="auto">
					<ExpandableContent>{props.children}</ExpandableContent>
				</Collapse>
			</StyledSection>
		)
	})
)

export default SideMenuStoreSectionExpandable
