import React from 'react'
import styled from 'styled-components'
import TypographyPro from 'themes/TypographyPro'
import { getTranslatedTextByKey, isMobileApp } from 'utils/utils'
import { respondAbove } from 'styles/mixins'
import SmartLink from 'static/components/SmartLink'
import { useRouter } from 'next/router'

const Title = styled(TypographyPro)`
	color: var(--strokeMenuTitle);
`

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 15px 20px 10px 20px;

	${respondAbove.md`
		padding: 15px 40px 10px 40px;
	`}

	${respondAbove.lg`
		flex-direction: row;
		padding: 25px 40px 10px 40px;
	`}
`

const ClickHereText = styled(TypographyPro)`
	color: var(--secondary);
	text-decoration: underline;
	cursor: pointer;
`

interface AllStoresAreClosedContentProps {
	userLanguage: string
}

const AllStoresAreClosedBar = ({ userLanguage }: AllStoresAreClosedContentProps) => {
	const router = useRouter()

	const handleClosed = (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation()

		SmartLink.route(`/${userLanguage}/store-locator`, router, !isMobileApp())
	}
	return (
		<Container data-testid="header-all-stores-closed">
			<Title variant="BodySmall">
				{getTranslatedTextByKey('webviewFlow.localization.bar.allStoresClosed.message', 'All stores are closed right now. For opening hours')}{' '}
				&nbsp;
			</Title>
			<ClickHereText onClick={handleClosed} variant="BodySmallClickable" data-testid="all-stores-closed-click-here-link">
				{getTranslatedTextByKey('webviewFlow.localization.bar.allStoresClosed.clickHereLink', 'Click here')}
			</ClickHereText>
		</Container>
	)
}

export default AllStoresAreClosedBar
