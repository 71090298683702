// @ts-nocheck
import React, { useState } from 'react'
import styled from 'styled-components/macro'
import TypographyPro from '../../../themes/TypographyPro'
import SideMenuExpandable from './SideMenuExpandable'
import { inject, observer } from 'mobx-react'
import { getTranslatedTextByKey } from 'utils/utils'

const StyledContent = styled.div`
	margin-top: 50px;
`

const StyledTitle = styled(TypographyPro)`
	opacity: 0.7;
	margin-bottom: 10px;
`

const SideMenuBottom = inject('Account')(
	observer((props) => {
		const { Account, skeletonData } = props

		const [expanded, setExpanded] = useState('')

		const isLoggedIn = !!Account.user
		const accountSection = {
			sectionId: 'account',
			name: getTranslatedTextByKey('account', Account),
			items: [
				{
					itemId: 'account',
					itemType: 'regularLink',
					path: '/account',
					text: getTranslatedTextByKey('account', Account),
				},
				{
					itemId: 'pastOrders',
					itemType: 'regularLink',
					path: '/account/past-orders',
					text: getTranslatedTextByKey('eCommerce.accountSettings.pastOrders', 'Past Orders'),
				},
				{
					itemId: 'paymentMethods',
					itemType: 'regularLink',
					path: '/account/payment-methods',
					text: getTranslatedTextByKey('eCommerce?.accountSettings?.paymentMethods', 'Payment Methods'),
				},
			],
		}

		return (
			<StyledContent data-testid="side-menu-bottom">
				<StyledTitle variant="h5" component="h5" data-testid="side-menu-more-about-us-title">
					{getTranslatedTextByKey('moreAboutUs', 'More about us')}
				</StyledTitle>
				{props.isEcommerce && (
					<SideMenuExpandable
						section={accountSection}
						expanded={expanded === 'account'}
						setExpanded={setExpanded}
						disabled={!isLoggedIn}
						isGeneratedStatically={props.isGeneratedStatically}
					/>
				)}
				{props.isEcommerce &&
					skeletonData?.sections &&
					skeletonData?.sections.map((section, key) => (
						<SideMenuExpandable
							section={section}
							key={key}
							expanded={section.sectionId === expanded}
							setExpanded={setExpanded}
							disabled={false}
							isGeneratedStatically={props.isGeneratedStatically}
						/>
					))}
			</StyledContent>
		)
	})
)

export default SideMenuBottom
