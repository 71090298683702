import React, { useEffect, useState, useContext, useMemo } from 'react'
import styled, { css } from 'styled-components/macro'
import TypographyPro from 'themes/TypographyPro'
import type _Infra from 'mobx/Infra'
import { getStoreName, getTranslatedTextByKey, initSessionAndLoadMenu, isMobileApp } from 'utils/utils'
import queryString from 'query-string'
import AllStoresAreClosedBar from './AllStoresAreClosedBar'
import { inject, observer } from 'mobx-react'
import storage from 'utils/storage'
import { StoreContext } from 'contexts/StoreContext'
import { useHistory } from 'react-router-dom'
import SmartLink from 'static/components/SmartLink'
import type { GetBranchesListRequestParameters } from 'mobx/Home/HomeDependencies'
import { mediaQueriesBreakpoints, respondAbove, respondTo } from 'styles/mixins'
import LocalizedAddressBar from './LocalizedAddressBar'
import { ORDER_TYPES, CONSTANTS } from 'utils/constants'
import loadMenu from 'utils/api/loadMenu/loadMenu'
import { isNextJS } from '../../../../../utils/nextUtils'
import ButtonBase from 'components/common/ButtonBase'
import IconComponent from 'themes/IconComponent'
import { useMediaQuery } from 'react-responsive'
import { sendCustomEvent } from 'utils/analytics/analytics'
import FeatureFlag from 'components/common/FeatureFlag'
import { FeatureFlagEnum } from 'types/constants.types'
import type { AppParams } from 'mobx/Infra/Infra.type'
import type { OrderType } from 'types/OrderType'
import { useRouter } from 'next/router'

const Container = styled.div<{ $isHomePage: boolean }>`
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: var(--clear);
	width: 100%;
	color: var(--clear);
	min-height: 46px;
	height: auto;
	justify-content: center;
	z-index: 1;
	position: relative;
	gap: 5px;
	border-radius: 0 0 10px 10px;

	${respondAbove.lg`
		flex-direction: row;
		padding: 25px 0px 20px 0px;
		gap: 0;
	`}
`

const HomepageHeaderTitle = styled(TypographyPro)`
	text-align: center;
`
const HomepageHeaderSubTitle = styled(TypographyPro)`
	text-align: center;
`

const TitleContainer = styled.div<{ $backdropEnabled: boolean; $isHomePage: boolean }>`
	display: flex;
	flex-direction: column;
	color: var(--strokeMenuTitle);
	box-shadow: var(--highlightShadow1);
	gap: 5px;
	align-items: center;
	background: var(--clear);
	z-index: 1300;
	position: relative;
	padding: 25px 40px 15px 40px;
	${({ $backdropEnabled }) => $backdropEnabled && `opacity: 0.1;`}

	${respondTo.md`
		${({ $isHomePage }: { $isHomePage: boolean }) =>
			!$isHomePage &&
			css`
				display: none;
			`}
	`}

    ${respondTo.lg`
		width: 100%;
	`}

    ${respondAbove.md`
		opacity: 1;
	`}

    ${respondAbove.lg`
		box-shadow: unset;	
		z-index: 1203;
		padding: 0 40px;
	`}
`

const DeliveryInfoOrderTypeText = styled(TypographyPro)`
	color: var(--strokeMenuTitle);
	margin-inline-end: 2px;
`

const DeliveryInfoValue = styled(TypographyPro)`
	color: var(--strokeMenuTitle);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	flex: 1;
`

const InnerContainer = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	max-width: 100%;
	position: relative;

	${respondAbove.lg`
		padding-bottom: 0;
		max-width: 50%;
	`}
`

const ButtonContainer = styled.div`
	padding: 5px 20px 10px 20px;
	width: 100%;

	${respondAbove.lg`
		padding: 5px 40px 0 40px;
	`}
`

const ShowMenuButton = styled(ButtonBase)<{ $isMobileView: boolean }>`
	width: 100%;
`

const StyledIconComponent = styled(IconComponent)`
	margin: 0 10px;
`

interface LocalizedHeaderProps {
	Home: {
		setBranchesList: (data: any) => void
		getBranchesList: GetBranchesListRequestParameters
		areAllStoresClosed: () => boolean
		locale: {
			msg: string
		}
	}
	User: {
		preferredLanguage: string
		orderType: number
		setOrderType: (type: string | number) => void
		setSession: (session: any) => Promise<void>
	}
	AddressManager: {
		getDeliveryAddressByOrderType: (orderType: 'delivery' | 'pickup') => ''
		getLocalizedOrderType: () => 'delivery' | 'pickup'
		onMenuClickWhenLocalized: () => Promise<{
			menuRes: any
			storeMetaData: any
			serverSession: any
			menuUrl: any
		}>
		getAddressFromLocaleStorage: () => any
	}
	Application: {
		isMobileApp: boolean
	}
	Infra: {
		setLoading: (val: boolean) => void
		appParams: AppParams & {
			features: { staticPagesWithAddressBox: string[] }
			eCommerce?: boolean
			ot?: string
			j?: string
		}
	}
	isGeneratedStatically: boolean
	handleLocalizedAddress: () => void
	page: string
	Cart: unknown
}

const LocalizedHeader = inject(
	'AddressManager',
	'Application',
	'Home',
	'User',
	'Infra',
	'Cart'
)(
	observer(
		({ Home, Infra, AddressManager, Application, User, Cart, isGeneratedStatically, page, handleLocalizedAddress }: LocalizedHeaderProps) => {
			const isStoreClosed = Home.areAllStoresClosed()
			const orderType = User.orderType === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PICKUP
			const router = useRouter()
			const [isHomePage, setIsHomePage] = useState(false)
			const [isMenuPage, setIsMenuPage] = useState(false)
			const [deliveryInfo, setDeliveryInfo] = useState('')
			const { store, setStore, nextJS } = useContext(StoreContext)

			const isMobileView = !useMediaQuery({ query: mediaQueriesBreakpoints.lg })

			useEffect(() => {
				if (orderType) {
					setDeliveryInfo(AddressManager.getDeliveryAddressByOrderType(orderType))
				}
			}, [orderType])

			useEffect(() => {
				setIsHomePage(
					Infra?.appParams?.features?.staticPagesWithAddressBox?.includes(page) ||
						window.location.pathname === '/' ||
						window.location.pathname === '/home'
				)
			}, [])

			useEffect(() => {
				setIsMenuPage(window.location.pathname.includes('/menu'))
			}, [])

			useEffect(() => {
				const loadSingleStoreMenu = async () => {
					const { appParams } = Infra
					const isEcommerce = appParams.eCommerce
					const queryParams = queryString.parse(window.location.search)
					// if there's no order type we don't go to the menu since we don't know which order type needs to be used
					if (!appParams.ot && appParams.j) {
						console.error(
							`The menu for store id: ${appParams.j} cannot be loaded since an error has occurred since the ot parameter was not provided in the URL.`
						)
					} else if (appParams.j) {
						// When getting here when loading the home page the previous page can be a few things:
						// 1. Store locator after selecting store and get the query params (j, c, l, ref, ot).
						// 2. Any other page when we are going back to the menu using the React router history hook.
						// 3. Not an E-commerce and should redirect back to the homepage as a defence process
						const queryParamsKeys = Object.keys(queryParams)
						if (!isEcommerce) {
							await initSessionAndLoadMenu(loadMenu, storage)(false, appParams.j, router, setStore, Home, Cart)
						}
						if (
							queryParamsKeys.includes('ot') &&
							queryParamsKeys.includes('j') &&
							queryParamsKeys.includes('c') &&
							queryParamsKeys.includes('l')
						) {
							User.setOrderType(queryParams.ot as OrderType)
							await initSessionAndLoadMenu(loadMenu, storage)(
								false,
								appParams.j,
								router,
								setStore,
								Home,
								Cart,
								null,
								User.orderType,
								true
							)
						}
					}
					Infra.setLoading(false)
				}
				loadSingleStoreMenu()
			}, [])

			const handleClosed = () => {
				SmartLink.route(`/${User.preferredLanguage}/store-locator`, router, !isMobileApp())
			}

			const getDeliveryInfoWithOrderType = () => {
				const translatedTextByOrderType = {
					delivery: getTranslatedTextByKey('webviewFlow.localization.localizedOrderType.deliveryTo', 'Delivery to'),
					pickup: getTranslatedTextByKey('webviewFlow.localization.localizedOrderType.pickupFrom', 'Pickup location'),
				}

				return (
					<>
						<DeliveryInfoOrderTypeText variant="BodySmallHighlighted" data-testid={`localized-order-type-${orderType}`}>
							{translatedTextByOrderType[orderType]}
						</DeliveryInfoOrderTypeText>
						<DeliveryInfoValue variant="BodySmall" data-testid="localized-address">
							{deliveryInfo}
						</DeliveryInfoValue>
					</>
				)
			}

			const onMenuClick = async (e: React.MouseEvent | React.TouchEvent) => {
				e.stopPropagation()
				e.preventDefault()

				const localeStoreName = getStoreName(store, orderType, Home)

				sendCustomEvent({
					category: 'Home Page menu button',
					event: 'Home Page menu button',
					action: 'button clicked',
					label: localeStoreName,
				})

				if (!Home.areAllStoresClosed()) {
					const { menuRes, storeMetaData, serverSession, menuUrl } = await AddressManager.onMenuClickWhenLocalized()
					await User.setSession(serverSession)

					setStore((prevStore: any) => ({ ...prevStore, data: menuRes, metaData: storeMetaData }))

					if (router) {
						router.push(`${menuUrl}`)
					} else if (isNextJS()) {
						nextJS.router.push(menuUrl)
					} else {
						window.location.replace(`${window.location.origin}${menuUrl}`)
					}
				} else {
					const lang = User.preferredLanguage
					if (isNextJS()) {
						nextJS.router.push(`/${lang}/menu`)
						return
					}

					const url = `/${lang}/menu${isMobileApp() ? '' : '.html'}`
					SmartLink.route(url, router, !Application.isMobileApp, '')
				}
			}

			const translationKeys = useMemo(() => {
				if (page?.includes('menu')) {
					return {
						title: getTranslatedTextByKey('webviewFlow.menu.addressBoxTitle'),
						subTitle: getTranslatedTextByKey('webviewFlow.menu.addressBoxSubTitle'),
					}
				}
				if (page?.includes('deals')) {
					return {
						title: getTranslatedTextByKey('webviewFlow.deals.addressBoxTitle'),
						subTitle: getTranslatedTextByKey('webviewFlow.deals.addressBoxSubTitle'),
					}
				}

				return {
					title: getTranslatedTextByKey('webFlowHomePageHeader'),
					subTitle: getTranslatedTextByKey('webFlowHomePageSubHeader'),
				}
			}, [page, User.preferredLanguage])

			return (
				<Container $isHomePage={isHomePage}>
					{!isMobileApp() && !isMobileView && (translationKeys?.title || translationKeys?.subTitle) && (
						<TitleContainer $backdropEnabled={false} $isHomePage={isHomePage}>
							{translationKeys?.title && (
								<HomepageHeaderTitle variant="HomePageTitle" component="h1" data-testid="address-box-title">
									{translationKeys?.title}
								</HomepageHeaderTitle>
							)}
							{translationKeys?.subTitle && (
								<HomepageHeaderSubTitle variant="BodySmall" component="h2" data-testid="address-box-subtitle">
									{translationKeys?.subTitle}
								</HomepageHeaderSubTitle>
							)}
						</TitleContainer>
					)}
					<InnerContainer>
						{isStoreClosed ? (
							<AllStoresAreClosedBar userLanguage={User.preferredLanguage} />
						) : (
							<LocalizedAddressBar
								address={getDeliveryInfoWithOrderType()}
								isGeneratedStatically={isGeneratedStatically}
								handleLocalizedAddress={handleLocalizedAddress}
								page={page}
							/>
						)}
						{!isMenuPage && !isMobileApp() && (
							<FeatureFlag flag={FeatureFlagEnum.ENABLE_HP_MENU_BUTTON}>
								<ButtonContainer>
									<ShowMenuButton
										id="HP_menu_button"
										$type="primary"
										size="small"
										fullWidth={false}
										onClick={onMenuClick}
										$isMobileView={isMobileView}
										data-testid="localized-go-to-menu-button"
									>
										<StyledIconComponent asset="/icons/mobileApp/appBar/active/menu.svg" />
										{getTranslatedTextByKey('showMenu')}
									</ShowMenuButton>
								</ButtonContainer>
							</FeatureFlag>
						)}
					</InnerContainer>
				</Container>
			)
		}
	)
)

export default LocalizedHeader
