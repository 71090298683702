// @ts-nocheck
import React, { useState, useEffect, useRef, useLayoutEffect, useContext, useMemo } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { inject, observer } from 'mobx-react'
import { codeToLocale, getLocaleStr, isMobile, isNodeJsEnv, sanitizeId, spaceToDash } from 'utils/utils'
import { StoreContext } from 'contexts/StoreContext'
import styled from 'styled-components'
import IconComponent from 'themes/IconComponent'
import { sendCustomEvent } from 'utils/analytics/analytics'
import { respondAbove } from 'styles/mixins'

// please see: https://stackoverflow.com/questions/61184102/react-material-tabs-indicator-not-showing-on-first-load
// this is being used to correct the scrollable tabs indicator to the width of title and icon
import Grow from '@material-ui/core/Grow'
import getElementByIdWrapper from '../../../utils/getElementByIdWrapper'
import { useMutationObserver } from 'hooks/useMutationObserver'
import { isNextJS } from '../../../../utils/nextUtils'
import { FeatureFlagEnum } from 'types/constants.types'

const TB_PLACEHOLDER_SECTION_ICON_URL = 'https://cdn.tictuk.com/defaultThemes/brands/tacobell/assets/icons/tbSpecial.svg'
const SectionIcon = (props) => <IconComponent {...props} />

const a11yProps = (index) => ({
	'aria-controls': `scrollable-auto-tabpanel-${index}`,
})

const StyledAppBar = styled(AppBar)`
	background-color: #fff;
	border-bottom: 1px solid var(--disable);
	${respondAbove.sm`
		z-index: 998;
		max-width: ${({ $maxWidth }) => $maxWidth};
	`}
`

const StyledTabs = styled(Tabs)`
	min-height: 70px;

	@media (max-width: 576px) {
		min-height: 58px;
	}

	& .MuiTabs-indicator {
		background-color: var(--strokeMenuTitle);
		height: 6px;
		left: 1px;
		@media (max-width: 576px) {
			height: 3px;
		}
	}
`

const StyledTab = styled(Tab)`
	color: var(--strokeMenuTitle);
	opacity: 1;
	${({ theme }) => theme.typography.h3};

	&& {
		min-height: 70px;
		max-width: 300px;
		@media (max-width: 576px) {
			min-height: 58px;
			padding: 6px 16px;
			margin: 0;
		}
		&:nth-child(1) {
			padding: 6px 0;
			margin: 0 20px 0 1px;
			@media (max-width: 576px) {
				margin: 0 16px 0 21px;
			}
		}
		padding: 6px 12px;
		margin: 0 9px;
	}
	&& .selected {
		background-color: var(--clear);
		color: var(--strokeMenuTitle);
	}
	&& .root {
		opacity: 1;
		max-width: 300px;
		&:hover {
			background-color: rgb(230, 230, 230);
		}
	}
	// for tab icon support
	span {
		display: flex;
		flex-direction: row;
		position: relative;
	}
	svg {
		margin-inline-end: 5px;
		margin-bottom: 0 !important;
	}
`

const StyledTabsContainer = styled.div`
	flex-grow: 1;
	width: 100%;
	background-color: var(--clear);
	position: relative;
	z-index: 0;
`

const StyledSectionIcon = styled(SectionIcon)`
	left: 0;
	width: 100vw;
	height: 100vh;
	max-width: 40px;
	max-height: 40px;
	@media (max-width: 576px) {
		max-width: 30px;
		max-height: 30px;
	}
`

const TAB_SECTION_Y_POS_MAP = []

const filterSectionsByVisibleItemExistence = (store) => {
	if (!store?.data?.items || !store?.data?.sections) {
		return []
	}

	return store.data.sections.filter((section) => {
		const sectionItems = section.itemIds.filter((_itemId) => store.data.items[_itemId] !== undefined)

		return sectionItems?.length
	})
}

/**
 * See https://material-ui.com/components/tabs/#automatic-scroll-button
 *
 * @returns {*}
 * @constructor
 */
const ScrollableTabs = inject(
	'Cart',
	'User',
	'Infra'
)(
	observer((props) => {
		const { Cart, User, Infra, headerOffset } = props
		const { store } = useContext(StoreContext)
		const sectionsWithVisibleItems = useMemo(() => filterSectionsByVisibleItemExistence(store), [store])
		const locale = User.preferredLanguage ? codeToLocale[User.preferredLanguage] : store.data?.locale

		const userClickedTab = useRef(false)

		const initialValue = `tab_section_${sanitizeId(sectionsWithVisibleItems?.[0]?.id)}`
		const [value, setValue] = useState(initialValue)
		const [winWidth, setWinWidth] = useState(isNodeJsEnv ? 0 : document.body.offsetWidth)

		const maxWidth = '100%'

		const tabId = `tab_section_${sanitizeId(sectionsWithVisibleItems[0]?.id)}`
		const element = useMutationObserver(`#${tabId}`)
		const onceRef = useRef(false)

		const handleChange = (event, newValue) => {
			userClickedTab.current = true
			setValue(newValue)

			const el = document.querySelector(`#${newValue}`)

			// anticipate the disappearance of the subheader, so tabs scroll correctly
			const headerBarHeight =
				Infra?.appParams?.features?.staticPagesKeepSubHeaderOnScroll?.includes('menu') && window.isGeneratedStatically
					? document.querySelector('#header-bar')?.clientHeight || 0
					: 0

			let scrollOffsetFromTop = (el?.offsetTop ?? 0) - headerOffset - el.clientHeight - headerBarHeight

			const firstSectionExtraHeight = Infra?.hasFeatureFlag(FeatureFlagEnum.MENU_GRID_HEADER)
				? document.querySelector(`#menu-grid-header`)?.clientHeight || 0
				: 0
			if (newValue === TAB_SECTION_Y_POS_MAP[0].tabId) {
				scrollOffsetFromTop -= firstSectionExtraHeight
			}

			window.scrollTo({
				behavior: 'smooth',
				top: scrollOffsetFromTop,
			})

			sendCustomEvent({
				category: 'menu',
				action: 'tab click',
				label: newValue,
			})
		}

		// see https://dev.to/n8tb1t/tracking-scroll-position-with-react-hooks-3bbj
		const getScrollPosition = () => ({ x: window.scrollX, y: window.scrollY })

		const useScrollPosition = (effect, deps, wait) => {
			const position = useRef(getScrollPosition())

			let throttleTimeout = null

			const callBack = () => {
				const currPos = getScrollPosition()
				effect({ prevPos: position.current, currPos })
				position.current = currPos
				throttleTimeout = null
			}

			useLayoutEffect(() => {
				const handleScroll = (e) => {
					// called when a scroll event fires
					if (wait) {
						if (throttleTimeout === null) {
							throttleTimeout = setTimeout(callBack, wait)
						}
					} else {
						callBack()
					}
				}

				// called once per mount NB no way to know if user triggered the scroll OR triggered by window.scrollTo !
				// see https://stackoverflow.com/questions/63426380/how-to-detect-if-user-triggered-scroll-event-vs-window-scroll
				window.addEventListener('scroll', handleScroll)

				return () => {
					// called once per unmount
					window.removeEventListener('scroll', handleScroll)
				}
			}, deps)
		}

		if (!isNodeJsEnv) {
			useScrollPosition(
				// must not run while server side rendering
				({ prevPos, currPos }) => {
					try {
						if (!Cart.menuItemOpen) {
							if (!userClickedTab.current) {
								// scroll triggered by user scrolling NOT ber clicking on a tab

								// if half the page is in the Y of the section then move tab to that section
								const halfWindowHeight = Math.floor(window.innerHeight / 2)
								const yPosPlusHalfWindow = currPos.y + halfWindowHeight - headerOffset

								for (let i = 0; i < TAB_SECTION_Y_POS_MAP.length; i++) {
									if (yPosPlusHalfWindow > TAB_SECTION_Y_POS_MAP[i].start) {
										if (i < TAB_SECTION_Y_POS_MAP.length - 1) {
											if (yPosPlusHalfWindow < TAB_SECTION_Y_POS_MAP[i + 1].start) {
												setValue(TAB_SECTION_Y_POS_MAP[i].tabId)
												break
											}
										} else {
											// the last tab is in view so do not test if Y is < the next tab's start Y
											setValue(TAB_SECTION_Y_POS_MAP[i].tabId)
											break
										}
									}
								}
							}

							userClickedTab.current = false
						}
					} catch (e) {
						console.error(e)
					}
				},
				[],
				500
			)
		}

		const windowResizeHandler = (e) => {
			setWinWidth(document.body.offsetWidth)
		}

		useEffect(() => {
			// PAGE_BOTTOM_IN_PX = document.documentElement.scrollHeight - window.innerHeight // + 104

			// initiate the event handler
			window.addEventListener('resize', windowResizeHandler, true)

			// this will clean up the event every time the component is re-rendered
			return function cleanup() {
				window.removeEventListener('resize', windowResizeHandler)
			}
		}, [windowResizeHandler])

		useEffect(() => {
			setWinWidth(document.body.offsetWidth)
		}, [winWidth])

		useEffect(() => {
			if (!element || onceRef.current) {
				return
			}

			const firstSectionExtraHeight = Infra?.hasFeatureFlag(FeatureFlagEnum.MENU_GRID_HEADER)
				? document.querySelector(`#menu-grid-header`)?.clientHeight || 0
				: 0

			onceRef.current = true
			sectionsWithVisibleItems.forEach((_section, i) => {
				const tabId = `tab_section_${sanitizeId(_section.id)}`
				getElementByIdWrapper(tabId, (el) => {
					if (el && el.offsetTop) {
						TAB_SECTION_Y_POS_MAP.push({
							start: el.offsetTop - headerOffset - firstSectionExtraHeight,
							end: 0,
							tabId,
						})
					}
				})
			})
		}, [element])

		return (
			<StyledTabsContainer>
				<StyledAppBar position="relative" color="default" $maxWidth={maxWidth} elevation={0} id="scrollableTabsHeader">
					<Grow in>
						<StyledTabs
							value={value}
							onChange={handleChange}
							variant="scrollable"
							scrollButtons="auto"
							aria-label="scrollable auto tabs example"
						>
							{sectionsWithVisibleItems.map((_section, i) => (
								<StyledTab
									key={_section.id}
									label={getLocaleStr(_section.title, locale)}
									value={`tab_section_${sanitizeId(_section.id)}`}
									id={`menu-page-bar-button-${spaceToDash(getLocaleStr(_section.title, 'en_US'))}`} // en_US because we prefer an ID in english
									data-testid="menu-section-tab"
									data-itemname={getLocaleStr(_section.title, locale)}
									{...a11yProps(i)}
								/>
							))}
						</StyledTabs>
					</Grow>
				</StyledAppBar>
			</StyledTabsContainer>
		)
	})
)

export default ScrollableTabs
