import React from 'react'
import { inject, observer } from 'mobx-react'
import ReCAPTCHA from 'react-google-recaptcha'

import type { InfraProps } from 'mobx/Infra/Infra.type'

const Recaptcha = inject(
	'Account',
	'Infra'
)(
	observer(({ Infra, onComplete, onError, onReady }: { Infra: InfraProps; onComplete: () => void; onError: () => void; onReady: () => void }) => {
		const onChange = (value: string) => {
			if (value) {
				onComplete()
			} else {
				onError()
			}
		}

		return <ReCAPTCHA size="normal" asyncScriptOnLoad={onReady} sitekey={Infra.appParams.features.reCaptcha?.siteKey} onChange={onChange} />
	})
)

export default Recaptcha
