// @ts-nocheck
import React from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import TypographyPro from '../../themes/TypographyPro'
import IconComponent from '../../themes/IconComponent'

const SpecialDiscountWarningWrapper = styled.div`
	margin-bottom: 20px;
	background: var(--infoBg);
	color: var(--fonts);
	border-radius: 2px;
	padding: 10px 15px;
	display: flex;
	gap: 10px;
`

const StyledInfoIcon = styled(IconComponent).attrs({
	asset: '/icons/info.svg',
})`
	min-width: 24px;
	height: auto;
`

const SpecialDiscountWarnings = inject(
	'Cart',
	'Application'
)(
	observer((props) => {
		const {
			Cart: { serverWarningsToDisplay },
			Application,
		} = props
		if (Application.page !== 'checkout') {
			return null
		}
		return (
			<>
				{serverWarningsToDisplay.map(({ text }) => (
					<SpecialDiscountWarningWrapper>
						<StyledInfoIcon />
						<TypographyPro component="div" variant="BodyRegular">
							{text}
						</TypographyPro>
					</SpecialDiscountWarningWrapper>
				))}
			</>
		)
	})
)
export default SpecialDiscountWarnings
