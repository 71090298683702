// @ts-nocheck
import React, { useContext } from 'react'
import TypographyPro from 'themes/TypographyPro'
import styled from 'styled-components/macro'
import { inject, observer } from 'mobx-react'
import { StoreContext } from '../../contexts/StoreContext'
import { getTranslatedTextByKey, isRTL, localeToCode } from '../../utils/utils'
import { isNil } from 'lodash-es'

// server data is provided starting from sunday
let daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

const OpeningHoursUl = styled.ul`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	list-style-type: none;
	color: var(--inactive);
	margin: 0;
`

const DailyHoursList = styled.ul`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	list-style-type: none;
	padding: 0;

	li {
		display: flex;
		justify-content: space-between;
		margin-bottom: 5px;
	}
`

const HoursListContainer = styled.div`
	display: flex;
	justify-content: space-between;
`

const OpeningHours = inject(
	'User',
	'Infra'
)(
	observer((props) => {
		const { store } = useContext(StoreContext)
		const restStore = store.data

		const { classes, User, isMobile, Infra } = props
		// name here is confusing, because locale is actually a 2 letters code, not like "en_US"
		const locale = User.preferredLanguage ? User.preferredLanguage : localeToCode[User.session.locale]

		let localeHoursIndex = 0

		for (let i = 0; i < restStore.openingHours.length; i++) {
			if (restStore.openingHours[i][locale]) {
				localeHoursIndex = i
				break
			}
		}

		const _openingHoursWithLocale = restStore.openingHours[localeHoursIndex]
		const [_localeKey] = Object.keys(_openingHoursWithLocale)
		let storeHours = _openingHoursWithLocale?.[_localeKey] || []

		// const openingHoursArr = [...openingHours.slice(1), openingHours[0]]
		if (!isNil(Infra.appParams?.firstDayOfWeekArrayIndex)) {
			storeHours = [...storeHours.slice(Infra.appParams.firstDayOfWeekArrayIndex), storeHours[0]]
			daysOfWeek = [...daysOfWeek.slice(Infra.appParams.firstDayOfWeekArrayIndex), daysOfWeek[0]]
		}

		const addressCapitalized = `${getTranslatedTextByKey('address')?.charAt(0)?.toUpperCase()}${getTranslatedTextByKey('address')?.slice(1)}`

		// format phone number to the shape of (xxx) xxxxxxx
		const formatPhoneNumber = (phoneNumber) => {
			const areaCode = phoneNumber.slice(0, 3)
			const restOfNumber = phoneNumber.slice(3)
			return `(${areaCode}) ${restOfNumber}`
		}

		return (
			<div className={!isMobile ? classes.hoursModal : null}>
				<OpeningHoursUl style={{ padding: isMobile ? '0 5px' : '18px' }}>
					<li>
						{storeHours.map((hours, dayIndex) => (
							<HoursListContainer key={dayIndex}>
								<TypographyPro variant="BodyRegularHighlighted">
									{getTranslatedTextByKey(`eCommerce[${daysOfWeek[dayIndex]}]`)}
								</TypographyPro>
								<DailyHoursList>
									{hours.map((hour) => (
										<li key={hour}>
											<TypographyPro variant="BodyRegular">{hour}</TypographyPro>
										</li>
									))}
								</DailyHoursList>
							</HoursListContainer>
						))}
					</li>

					<li style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'space-between' }} key={restStore.address.formatted}>
						<div
							style={{
								fontWeight: 'bold',
								marginBottom: '5px',
							}}
						>
							{addressCapitalized}:
						</div>
						<div
							style={
								isMobile
									? {
											width: 145,
									  }
									: { width: 220 }
							}
						>
							{restStore.address.formatted}
						</div>
					</li>

					<li style={{ display: 'flex', justifyContent: 'space-between' }} key={restStore.phoneNumber}>
						<div style={{ fontWeight: 'bold' }}>{getTranslatedTextByKey('phoneNumber')}</div>
						<div
							style={{
								width: isMobile ? 145 : 220,
								direction: isRTL(User?.session?.locale) && 'ltr',
							}}
						>
							{formatPhoneNumber(restStore.phoneNumber)}
						</div>
					</li>
				</OpeningHoursUl>
			</div>
		)
	})
)

export default OpeningHours
