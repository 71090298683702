// @ts-nocheck
import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'

const StyledTypography = styled(Typography)`
	font-weight: bold;
`

const StyledContentTypography = styled(Typography)`
	margin-inline-end: 10px;
`

const StyledDeliveryList = styled.div`
	margin-inline-start: ${({ isMobile }) => isMobile && '10%'};
	margin-bottom: 20px;
`

const StyledDeliveryContent = styled.div`
	display: flex;
`

const DeliveryAreaContent = ({ areaTitle, isMobile, deliveryFee, minOrder, deliveryTime, color }) => (
	<StyledDeliveryList $isMobile={isMobile}>
		<StyledDeliveryContent>
			<FiberManualRecordIcon style={{ color }} />
			<StyledTypography>{areaTitle}</StyledTypography>
		</StyledDeliveryContent>
		<StyledDeliveryContent>
			<AttachMoneyIcon />
			<StyledContentTypography>{deliveryFee}</StyledContentTypography>
			<AccessTimeIcon />
			<StyledContentTypography>{deliveryTime}</StyledContentTypography>
			<ShoppingCartIcon />
			<StyledDeliveryContent>{minOrder}</StyledDeliveryContent>
		</StyledDeliveryContent>
	</StyledDeliveryList>
)

export default DeliveryAreaContent
