export const KIOSK_ORDER_TYPES = ['dineIn', 'takeAway', 'driveThru'] as const
export type KioskOrderType = typeof KIOSK_ORDER_TYPES[number]

export enum PaymentMethod {
	CASH = 'CASH',
	CREDIT = 'CREDIT',
}

export enum FeatureFlagEnum {
	DISABLE_SIGN_UP = 'disableSignUp',
	SHOW_STORE_DESCRIPTION = 'showStoreDescription',
	HIDE_PRICE_FOR_UNLOCALIZED_ITEM = 'hidePriceForUnlocalizedItem',
	ENABLE_BROWSE_CATEGORIES = 'enableBrowseCategories',
	LAZY_LOAD_BROWSE_CATEGORIES = 'lazyLoadBrowseCategories',
	USE_NEW_ADDRESS_BOX = 'useNewAddressBox',
	ASK_TO_RATE_THE_APP = 'askToRateTheApp',
	SHOW_PAST_ORDERS_ON_HOME_PAGE = 'showPastOrdersOnHomePage',
	ENABLE_HP_MENU_BUTTON = 'enableHPMenuButton',
	ADDRESS_BOX_POPUP_TOP = 'addressBoxPopupTop',
	MENU_GRID_HEADER = 'menuGridHeader',
	SIGN_IN_MANDATORY_EMAIL = 'signInMandatoryEmail',
}

export type GenericCallback = (...args: any[]) => any

declare global {
	interface Window {
		opera: string
		MSStream: string
	}
}
