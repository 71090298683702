// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { formatPrice, getLocaleStr, getTranslatedTextByKey } from 'utils/utils'
import styled from 'styled-components'
import { isEmpty } from 'lodash-es'
import TypographyPro from '../../themes/TypographyPro'
import { inject } from 'mobx-react'
import { ORDER_TYPES } from 'utils/constants'

const StyledCostSummary = styled(TypographyPro)`
	box-shadow: ${({ isCheckout }) => isCheckout && 'lightgrey 0px -2px 4px'};
	color: var(--footerAndDarkBackgrounds);
`

const StyledDiscountOrCharge = styled(TypographyPro).attrs({
	'data-testid': 'discount-or-charge-container',
})`
	display: flex;
	justify-content: space-between;
	margin: 15px 0 0;
	color: var(--footerAndDarkBackgrounds);
`

const List = styled.ul`
	margin-inline-start: 20px;
	margin: 0 20px;
	padding: 0;
`

/**
 * Used to show the summary of charges provided by the server eg tax, discounts, free items.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const CostSummaryForChat = inject('Infra')((props) => {
	const { isChatApp, tip } = props
	const _charges = props.serverCharges
	const _addedItemsFromDiscounts = props.serverAddedItemsFromDiscounts
	const [coupons, setCoupons] = useState([])

	useEffect(() => {
		const _pathname = window.location.pathname
		const snackbarMessagesList = []

		_charges.forEach((_charge) => {
			const couponTitle = getLocaleStr(_charge.title, props.locale)
			if (_pathname.indexOf('/menu') > -1) {
				if (
					!_charge.zeroAmountDiscount &&
					!coupons.includes(couponTitle) &&
					!isEmpty(_charge.discountId) &&
					_charge.discountId === _charge.code &&
					_charge.type === 'discount'
				) {
					snackbarMessagesList.push(
						<li key={couponTitle}>
							<strong>{couponTitle}</strong> coupon was applied to your order
						</li>
					)
					setCoupons((cpns) => [...cpns, couponTitle])
				}
			}
		})

		if (snackbarMessagesList.length) {
			const snackbarContent = <List>{snackbarMessagesList.map((item) => item)}</List>
			setTimeout(() => {
				props.Infra.showSnackbar({
					snackId: 'cart',
					message: snackbarContent,
					status: 'deal',
				})
			}, 100)
		}
	}, [_charges.length])

	return (
		<StyledCostSummary variant="BodySmall" component="div">
			{_addedItemsFromDiscounts?.length > 0 &&
				_addedItemsFromDiscounts.map((_discount, _idx) => {
					if (_discount.title) {
						return (
							<StyledDiscountOrCharge key={_idx} variant="BodySmall">
								<span>{getLocaleStr(_discount.title, props.locale)}</span>
								{!props.isCartEmpty && <span>{formatPrice(_discount.price, props.currency, props.countryCode)}</span>}
							</StyledDiscountOrCharge>
						)
					}
				})}

			{/* if charge has zeroAmountDiscount:true then it's an added item via a discount which will be displayed
				by the above array so ignore it below */}

			{_charges?.length > 0 &&
				_charges.map((_charge, _idx) => {
					if (!_charge.zeroAmountDiscount || _charge.freeDelivery) {
						return (
							!(isChatApp && _charge.type === 'delivery') && (
								<StyledDiscountOrCharge key={_idx} variant="BodySmall">
									<span data-testid={`charges-title-${_idx}`}>
										{_charge.type === ORDER_TYPES.DELIVERY
											? getTranslatedTextByKey('deliveryCost', 'Delivery Fee')
											: getLocaleStr(_charge.title, props.locale)}
									</span>
									{(_charge.type === ORDER_TYPES.DELIVERY || !props.isCartEmpty) && (
										<span data-testid="charges-amount-delivery-fee">
											{formatPrice(_charge.amount, props.currency, props.countryCode)}
										</span>
									)}
								</StyledDiscountOrCharge>
							)
						)
					}
				})}

			{!!tip && (
				<StyledDiscountOrCharge variant="BodySmall">
					<span>{getTranslatedTextByKey('webviewFlow.orderConfirmation.tip', 'Tip')}</span>
					<span>{formatPrice(tip, props.currency, props.countryCode)}</span>
				</StyledDiscountOrCharge>
			)}

			{props.showTotal && (
				<StyledDiscountOrCharge variant="SubheadHighlighted">
					<span>{props.totalToPayText}</span>
					<span data-testid="total-price">{formatPrice(props.serverGrandTotal, props.currency, props.countryCode)}</span>
				</StyledDiscountOrCharge>
			)}
		</StyledCostSummary>
	)
})

export default CostSummaryForChat
