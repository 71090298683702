// @ts-nocheck
import React, { useState, useContext, useEffect, useRef } from 'react'
import { inject, observer } from 'mobx-react'
import { useMediaQuery } from 'hooks/useMediaQuery'
import styled, { css } from 'styled-components/macro'
import { formatPrice, isIPhoneX, injectVarsToTemplateString, getTranslatedTextByKey, checkRequest, getDomainByEnv } from 'utils/utils'
import CouponCode from '../menu/CouponCode'
import { StoreContext } from 'contexts/StoreContext'
import CostSummaryForChat from './CostSummaryForChat'
import CartSummaryRows from './CartSummaryRows'
import { breakpoints, respondAbove } from 'styles/mixins'
import { CONSTANTS, ORDER_TYPES } from 'utils/constants'
import TypographyPro from '../../themes/TypographyPro'
import { isEmpty } from 'lodash-es'
import useSnackbar from 'hooks/useSnackbar'
import CouponCodeOnNextStep from 'components/menu/couponCodeOnNextStep/CouponCodeOnNextStep'
import TaxDisclaimer from '../common/TaxDisclaimer'
import IconComponent from 'themes/IconComponent'
import storage from 'utils/storage'
import loadMenu, { fetchMenu } from 'utils/api/loadMenu/loadMenu'
import CheckoutButton from 'components/common/CheckoutButton'

const StyledMinimumOrderInfo = styled(TypographyPro)`
	line-height: 16px;
`
/**
 * This component have 2 main cases to show up:
 * 1. Menu page on WEB session as a sidebar.
 * 2. Summary page on Mobile session as a main component.
 * Just because of that we are using the isMobile flag to separate these 2 cases.
 * */
const Summary = styled.div`
	position: fixed;
	// We add 20 pixels in order to make sure the text and the Checkout button blur don't overlap.
	// This is hard-coded and bad, but a better solution requires an overhaul of the Cart page.
	bottom: ${({ $mobileApp }) => ($mobileApp ? 125 : 65) + 20}px;
	margin-top: 16px;
	width: ${({ $isMobile, $isHover }) => ($isMobile || $isHover ? 100 : 25)}%;
	padding: 10px 10px 5px;
	border-top: 1px solid var(--disable);
	background-color: var(--clear);

	@media (min-width: 1024px) {
		padding: 15px 10px;
		bottom: 70px;
		width: ${({ $isHover }) => ($isHover ? 100 : 25)}%;
		padding-block-start: 15px;
		border-bottom: 1px solid var(--disable);
	}
`

const CartSummaryRowsContainer = styled.div`
	${({ $isCheckout }) =>
		!$isCheckout &&
		css`
			overflow-y: auto;
			height: calc(${({ $height }) => $height}px - 15px);
		`};

	${respondAbove.md`
		padding-bottom: 10px;
	`}

	${isIPhoneX() && 'flex: 1'};
	${({ $isCheckout, $isMobile, $height }) =>
		!$isCheckout &&
		!$isMobile &&
		css`
			padding-bottom: unset;
			overflow-y: auto;
			height: ${({ $isMobile }) => ($isMobile ? 450 : $height)}px;
		`};
`

const CartContainer = styled.div`
	${({ $isCheckout }) =>
		!$isCheckout &&
		css`
			display: flex;
			flex-direction: column;

			@media (min-width: 1024px) {
				min-height: 82vh;
			}
		`}
`

const CostSumForChatContainer = styled.div`
	@media (min-width: 1024px) {
		padding: 0;
	}
`

const SummaryTitle = styled(TypographyPro)`
	text-align: center;
	padding: 21.5px 0;
	color: var(--strokeMenuTitle);
`

const EmptyCart = styled.div`
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	padding: 0 21px;
`

const Container = styled.div`
	${({ $isCheckout, $isMobile }) =>
		!$isCheckout &&
		($isMobile
			? css`
					width: 100%;
					height: auto;
			  `
			: css`
					position: fixed;
					border-left: 1px solid var(--disable);
					z-index: 1102;
					width: 25%;
					height: 100%;
					right: 0;
					bottom: 0;
			  `)}
	background-color: var(--clear);

	${({ $isHover, $isOpen }) =>
		$isHover &&
		css`
			bottom: 0;
			z-index: 1200;
			animation: ${!$isOpen ? 'cart-disappears 0.3s forwards' : 'cart-appears 0.3s forwards'};
			transition-timing-function: ease-out;
			right: -100%;
		`} @keyframes cart-disappears {
		0% {
			right: 0;
			transform: scale(1) rotateY(0deg);
		}

		100% {
			right: -100%;
			transform: scale(0.5) rotateY(45deg);
		}
	}

	@keyframes cart-appears {
		0% {
			right: -100%;
			transform: scale(0.7) rotateY(0deg);
		}
		100% {
			right: 0;
			transform: scale(1) rotateY(0deg);
		}
	}
`
const MenuWrapper = styled.div`
	background-color: #000c;
	position: fixed;
	top: 0;
	left: 0;
	opacity: 0;
	${(props) =>
		props.isClosing
			? css`
					animation: fade-out 0.3s forwards;
			  `
			: css`
					width: 100vw;
					height: 100vh;
					animation: fade-in 0.2s forwards;
			  `};
	z-index: 1200;
`

const CloseIcon = styled(IconComponent)`
	position: absolute;
	top: calc(50% - 15px);
	left: -39px;
	transform: translateX(-50%);
	border-radius: 50%;
	width: 30px;
	height: 30px;
	padding: 7px;
	cursor: pointer;
	border: solid 1px var(--clear);
`

const CartSummary = inject(
	'Cart',
	'Infra',
	'Application',
	'User',
	'MobileApplication'
)(
	observer((props) => {
		const { store, setStore, nextJS } = useContext(StoreContext)
		const rest = store.data ?? {}
		const { Cart, User, isCheckout, Infra, isOpen, isHover, setShowCartSummary, className, MobileApplication } = props
		const [containerHeight, setContainerHeight] = useState(0)
		const [windowHeight, setWindowHeight] = useState(0)
		const summaryRef = useRef()
		useSnackbar(summaryRef, 'cart')
		const isChatApp = User.isChatApp()
		const integrationTaxEnabled = User.session.isIntegrationTaxEnabled
		const currentPageIsSummary = props.isSummary
		const isMobileView = useMediaQuery({ query: `(max-width: ${breakpoints.sm})` })
		const shouldShowOnlyCheckOutButton = isMobileView && !currentPageIsSummary && !isCheckout
        const showCheckoutButton = Cart.numberOfItems !== 0 || currentPageIsSummary || isCheckout || !isMobileView

		useEffect(() => {
			// useEffect is needed because some type of coupons gets rendered before useEffect get called
			setTimeout(() => {
				if (summaryRef?.current?.offsetHeight) {
					const summaryHeight = summaryRef.current.offsetHeight
					const mobileHeight = windowHeight - summaryHeight - 68 - 120
					const desktopHeight = windowHeight - summaryHeight - 100 - 40
					setContainerHeight(isMobileView ? mobileHeight : desktopHeight)
				}
			}, 300)
		}, [Cart.serverCharges.length, windowHeight, Cart.items])

		const reportWindowSize = () => {
			setWindowHeight(window.innerHeight)
		}

		useEffect(() => {
			// when initialize - deduct from the window height the summary (and the button) and the header
			setContainerHeight(window.innerHeight - 106 - 200)
			setWindowHeight(window.innerHeight)

			window.addEventListener('resize', reportWindowSize)

			return function cleanup() {
				window.removeEventListener('resize', reportWindowSize)
			}
		}, [])

		const deliveryType = User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : 'takeout'
		const defaultMinOrderPrice = Cart.deliveryInfo?.minOrderPrice ?? store.metaData?.orderDeliveryInfo?.minOrderPrice
		const defaultDeliveryTime =
			store.data?.deliveryInfo?.type === deliveryType && store.data?.deliveryInfo?.filter((info) => info.type === deliveryType)[0].delayMins

		const renderCouponArea = () => {
			if (User.session.hideCouponFieldOnMenuPage) {
				return <CouponCodeOnNextStep />
			}
			if (!isCheckout) {
				return <CouponCode isMenu={props.isMenu} isSummary={props.isSummary} />
			}
		}

		const initMenu = async (chainId, storeId, orderType) => {
			const menu = await loadMenu({
				chainId,
				storeId,
				orderTypeFromQS: orderType,
				appId: User.session.appid,
				stopLoading: false,
				storage,
			})
			return menu
		}

		useEffect(() => {
			const init = async () => {
				const oldCartFromStorage = storage.getStorage()
				const storeId = localStorage.getItem('storeId')
				if (oldCartFromStorage && storeId) {
					const menu = await fetchMenu(Infra.appParams.c || Infra.appParams.pc, storeId)
					setStore(() => ({ ...store, data: menu, items: menu.items }))

					try {
						const storeIdS = process.env.NODE_ENV === 'production' || Infra?.appParams?.useProductionMenu ? storeId : `${storeId}_dev`
						const serverSession = await checkRequest({
							wru: getDomainByEnv(),
							request: MobileApplication.getSessionId(),
							cust: 'openRest',
							j: storeIdS,
						})
						await User.setSession(serverSession)
						storage.updateItemsFromStorage(menu)
					} catch (error) {
						console.error(error)
					}
				}
			}
			const chainId = Infra.appParams.c || Infra.appParams.pc

			if ((isHover || isMobileView) && Cart.numberOfItems === 0 && chainId) {
				init()
			}
		}, [User.session.appid])

		return (
			<>
				{isHover && !shouldShowOnlyCheckOutButton && <MenuWrapper isClosing={!isOpen} />}
				<Container
					$isCheckout={isCheckout}
					$isMobile={isMobileView}
					$isOpen={isOpen}
					$isHover={isHover}
					className={className}
					data-testid="cart-container"
				>
					{isHover && !shouldShowOnlyCheckOutButton && (
						<CloseIcon asset="/icons/closeWhite.svg" onClick={() => setShowCartSummary(!isOpen)} />
					)}
					{!shouldShowOnlyCheckOutButton && !isCheckout && (
						<SummaryTitle variant="h2">{getTranslatedTextByKey('orderSummary')}</SummaryTitle>
					)}

					{Cart.numberOfItems === 0 && !shouldShowOnlyCheckOutButton && <EmptyCart>{getTranslatedTextByKey('emptyCart')}</EmptyCart>}
					<CartContainer $isCheckout={isCheckout}>
						{!shouldShowOnlyCheckOutButton && (
							<CartSummaryRowsContainer
								$isCheckout={isCheckout}
								$isMobile={isMobileView}
								$height={containerHeight}
								data-testid="cart-summary-rows-container"
							>
								<CartSummaryRows
									// the prop key bellow allow the component to re-render if the we navigate on different URL but on the same nextJS page
									// ex:  from  /menu?wru...  to   /menu?wru.....#itemId=xxxx
									key={nextJS?.router.asPath}
									cartItems={Cart.items}
									isCheckout={isCheckout}
									rest={rest}
									Cart={Cart}
									User={User}
									allowUpdate
								/>
							</CartSummaryRowsContainer>
						)}
						{!isCheckout && (
							<>
								{!shouldShowOnlyCheckOutButton && (
									<Summary ref={summaryRef} $isMobile={isMobileView} $mobileApp={props.Application.isMobileApp} $isHover={isHover}>
										{renderCouponArea()}

										<CostSumForChatContainer data-testid='cost-sum-container'>
											<CostSummaryForChat
												serverCharges={Cart.serverCharges}
												serverAddedItemsFromDiscounts={Cart.addedItemsFromDiscounts}
												currency={rest.currency}
												countryCode={rest.countryCode}
												serverGrandTotal={Cart.serverGrandTotal}
												totalToPayText={getTranslatedTextByKey('expressCheckout.total')}
												locale={rest.locale}
												showTotal={isCheckout}
												isCartEmpty={isEmpty(Cart.items)}
												isChatApp={isChatApp}
											/>
										</CostSumForChatContainer>
										{store.metaData?.features?.CART_DELIVERY_STATIC_MIN_ORDER_TITLE?.enabled && (
											<StyledMinimumOrderInfo variant="BodySmall">
												{getTranslatedTextByKey(store.metaData.features.CART_DELIVERY_STATIC_MIN_ORDER_TITLE.config?.textKey)}
											</StyledMinimumOrderInfo>
										)}
										{defaultMinOrderPrice > 0 && !store.metaData?.features?.CART_DELIVERY_STATIC_MIN_ORDER_TITLE?.enabled && (
											<StyledMinimumOrderInfo variant="BodySmall">
												{injectVarsToTemplateString(getTranslatedTextByKey('webviewFlow.minimumSpent'), {
													orderType:
														deliveryType === ORDER_TYPES.DELIVERY
															? getTranslatedTextByKey(ORDER_TYPES.DELIVERY)
															: getTranslatedTextByKey(ORDER_TYPES.PEAKUP),
													amountSpent: '',
												})}
												<span> {formatPrice(defaultMinOrderPrice, store.data?.currency, store.data?.countryCode)}</span>
											</StyledMinimumOrderInfo>
										)}
										{defaultDeliveryTime > 0 && (
											<StyledMinimumOrderInfo variant="BodySmall">
												{injectVarsToTemplateString(getTranslatedTextByKey('webviewFlow.estimatedTimeToOrder'), {
													orderType:
														deliveryType === ORDER_TYPES.DELIVERY
															? getTranslatedTextByKey(ORDER_TYPES.DELIVERY)
															: getTranslatedTextByKey(ORDER_TYPES.PEAKUP),
													estimatedTimeNumber: defaultDeliveryTime,
												})}
											</StyledMinimumOrderInfo>
										)}
										{integrationTaxEnabled && <TaxDisclaimer />}
									</Summary>
								)}
								{showCheckoutButton && (
									<CheckoutButton
										currency={rest.currency}
										serverGrandTotal={Cart.serverGrandTotal}
										cartItems={Cart.items}
										calcNumberOfItems={Cart.calcNumberOfItems}
										disabled={Cart.numberOfItems === 0}
										fullWidth={isHover}
									/>
								)}
							</>
						)}
					</CartContainer>
				</Container>
			</>
		)
	})
)

export default CartSummary
