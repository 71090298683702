// @ts-nocheck
import React from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import TypographyPro from 'themes/TypographyPro'
import { respondAbove } from 'styles/mixins'
import CTAText from 'components/common/CTAText'

const Hr = styled.hr`
	border-width: 0 0 1px 0;
	border-color: #efeff0;
	width: 100%;
`

const ContainerStyledGrid = styled(Grid)`
	align-self: end;
	margin-bottom: 30px;
	margin-top: 20px;

	${respondAbove.md`
		position: unset;
		bottom: unset;
		margin-top: 12px;
		width: 100%;
	`}
`

const BottomStyledGrid = styled(Grid)`
	width: 100%;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`

const OrText = styled(TypographyPro)`
	color: var(--font);
	background-color: white;
	padding-left: 10px;
	padding-right: 10px;
`

const StyledSkipTextGrid = styled(Grid)`
	margin-top: 20px;

	${respondAbove.md`
		margin-top: 10px;
	`}
`

const Skip = ({ onSkip, caption, or }) => (
	<ContainerStyledGrid>
		<BottomStyledGrid container>
			<Hr />
			<Grid
				item
				style={{
					position: 'absolute',
				}}
			>
				<OrText variant="BodyRegular">{or}</OrText>
			</Grid>
		</BottomStyledGrid>
		<StyledSkipTextGrid id="sign-page-skip-button" container direction="row" justifyContent="center">
			<CTAText $size="big" onClick={onSkip} data-testid="account-skip">
				{caption}
			</CTAText>
		</StyledSkipTextGrid>
	</ContainerStyledGrid>
)

export default Skip
