import { useMediaQuery as useMediaQueryFromReactResponsive } from 'react-responsive'
import Application from 'mobx/Application/Application'

export const useMediaQuery = ({ query }: { query: string }) => {
	if (typeof window === 'undefined') {
		// we want to return true if we are on mobile
		// need to continue for others cases
		if (query === '(max-width: 600px)') {
			return Application.isMobileApp || Application.isMobileView
		}
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const umq = useMediaQueryFromReactResponsive({ query })
		// On nextJS, Application.isMobileView is setted on server side in _app.tsx with a different behaviour :
		// 1 - Application.isMobileView will be false if a web browser is used on a computer without device mode enabled, even if window width is reduced under 600px (because there is no way to detect the screen width on server side)
		// 2 - Application.isMobileView will be true  if a web browser is used on a computer with device mode enabled, or a real mobile/tablet (because http request user agent contains the info of used device)
		// To avoid side effect on nextJS on case 1, we force Application.isMobileView to true if we detect a small screen on client side (with react-responsive)
		// so by calling isMobile() from utils, it will return true like useMediaQuery does, so everything stays aligned
		// The only downside is a hydratation warning from nextJS bundle (because isMobileView is false on SSR and become true on CSR)
		// but this can be ignored for now
		if (query === '(max-width: 600px)' && !Application.isMobileView && umq === true) {
			Application.setIsMobileView(true)
		}

		return umq
	}

	return false
}
