// @ts-nocheck
import React from 'react'
import CustomPlaceholderLoader from './CustomPlaceholderLoader'

const AddressBoxLoader = ({ width, height, viewBox, className }: { width?: number; height?: number; viewBox?: string; className?: string }) => (
	<CustomPlaceholderLoader viewBox={viewBox} height={height || 327} width={width || 440} className={className}>
		<rect x="0" y="0" rx="2" ry="2" width="440" height="327" />
	</CustomPlaceholderLoader>
)

export default AddressBoxLoader
