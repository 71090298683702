import styled from 'styled-components/macro'
import IconComponent from 'themes/IconComponent'

const CloseIcon = styled(IconComponent).attrs({
	asset: '/icons/closeWhite.svg',
})`
	position: absolute;
	top: 110%;
	left: 50%;
	transform: translateX(-50%);
	background-color: var(--footerAndDarkBackgrounds);
	opacity: 0.6;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	padding: 7px;
	cursor: pointer;
	border: solid 1px var(--clear);
	display: ${({ $show }) => !$show && 'none'};
`

export const CloseIconItemPupopTop = styled(IconComponent).attrs({
	asset: '/icons/closeWhite.svg',
})`
	position: absolute;
	top: -40px;
	right: 0;
	transform: translateX(-50%);
	background-color: var(--footerAndDarkBackgrounds);
	opacity: 0.6;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	padding: 7px;
	cursor: pointer;
	border: solid 1px var(--clear);
	display: ${({ $show }) => !$show && 'none'};
`

export default CloseIcon
