// @ts-nocheck
import React, { useState } from 'react'
import styled from 'styled-components/macro'
import MobileMenuStoreExpandable from './SideMenuStoreSectionExpandable'
import OpeningHours from '../../menu/OpeningHours'
import { makeStyles } from '@material-ui/core/styles'
import DeliveryAreas from '../../menu/DeliveryAreas/DeliveryAreas'
import { inject, observer } from 'mobx-react'
import { getTranslatedTextByKey } from 'utils/utils'
import { GOOGLE_API_KEY } from 'utils/constants'

const useStyles = makeStyles({
	menuContainerMobile: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
	},
	menuContainerDesktop: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	clearIcon: {
		margin: '12px 0',
		marginInlineStart: '14px',
		alignSelf: 'flex-start',
		cursor: 'pointer',
	},
	arrowDownIcon: {
		alignSelf: 'flex-end',
	},
	paper: {
		width: '95%',
		display: 'flex',
		flexDirection: 'column',
		'@media (min-width: 1280px)': {
			width: '30%',
		},
	},
	link: {
		color: 'black',
		display: 'flex',
		flexDirection: 'row',
		marginInlineEnd: '30px',
	},
	typography: {
		marginInlineStart: '14px',
		marginBottom: '8px',
		marginTop: '8px',
		justifyContent: 'center',
		alignItems: 'center',
	},
	typographyDesktop: {
		marginInlineStart: '5px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
	},
	openingHours: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		color: 'black',
	},
	openingHoursDesktop: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		marginInlineStart: '17px',
		marginInlineEnd: '30px',
		color: 'black',
	},
	hoursModal: {
		position: 'absolute',
		backgroundColor: 'white',
		top: '75%',
		width: '300px',
		zIndex: '2',
		border: '1px solid rgba(0, 0, 0, 0.04)',
		boxShadow: '0 16px 24px 2px rgba(0, 0, 0, 0.14)',
	},
	menuLinks: {
		marginTop: '20px',
	},
	version: {
		marginTop: 'auto',
		alignSelf: 'flex-end',
		margin: '0 5px 5px 0',
	},
})

const MenuStore = styled.div`
	margin-bottom: 45px;
`

const SideMenuStoreSection = inject('Infra')(
	observer((props) => {
		const [expanded, setExpanded] = useState('')
		const classes = useStyles()

		const { Infra } = props
		const { locale: localeData } = Infra
		const googleApiKey = localeData?.key?.trim() || GOOGLE_API_KEY

		return (
			<MenuStore>
				{props.doOpeningHoursExist && (
					<MobileMenuStoreExpandable
						title={getTranslatedTextByKey('openingHours', 'Opening hours')}
						expanded={expanded}
						setExpanded={setExpanded}
					>
						<OpeningHours classes={classes} isMobile />
					</MobileMenuStoreExpandable>
				)}

				{props.doDeliveryAreasExist && (
					<MobileMenuStoreExpandable
						title={getTranslatedTextByKey('deliveryInfo', 'Delivery areas')}
						expanded={expanded}
						setExpanded={setExpanded}
					>
						<DeliveryAreas isMobile deliveryInfo={props.deliveryInfo} apiKey={googleApiKey} />
					</MobileMenuStoreExpandable>
				)}
			</MenuStore>
		)
	})
)

export default SideMenuStoreSection
